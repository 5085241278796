/**
 * Component: MobileNavigation
 */

export default class MobileNavigation {
    constructor() {
        this.body = $('body');
        this.mobileNav = $('#mobileNavigation');
        this.toggleSecondLevelLinks = $('.toggle-second-level');
        this.toggleThirdLevelLinks = $('.toggle-third-level');
        this.secondLevelBackButtons = $('.mobile-navigation__back-button--second');
        this.thirdLevelBackButtons = $('.mobile-navigation__back-button--third');
        this.secondLevelContainer = $('.mobile-navigation__container--second-level');
        this.thirdLevelContainer = ('.mobile-navigation__container--third-level');
        this.secondLevelLists = $('.mobile-navigation__list--second-level');
        this.thirdLevelLists = ('.mobile-navigation__list--third-level');

        this.toggleSecondLevel = this.toggleSecondLevel.bind(this);
        this.toggleThirdLevel = this.toggleThirdLevel.bind(this);

        this.handleSecondLevelBackButton = this.handleSecondLevelBackButton.bind(this);
        this.handleThirdLevelBackButton = this.handleThirdLevelBackButton.bind(this);

        this.openInSameTab = this.openInSameTab.bind(this);

        this.init();
    }
    init() {
        $(document).on('click', '.mobile-navigation__list-item:not(.link-out)', this.toggleSecondLevel);
        $(document).on('click', '.mobile-navigation__list-item--second', this.toggleThirdLevel);
        $(document).on('click', '.mobile-navigation__back-button--second', this.handleSecondLevelBackButton);
        $(document).on('click', '.mobile-navigation__back-button--third', this.handleThirdLevelBackButton);
        $(document).on('click', '.mobile-navigation__product-category-link, .mobile-navigation__product-card .link, .tablet-link', this.openInSameTab);
    }

    toggleSecondLevel(event) {
        event.preventDefault();
        event.stopPropagation();
        const targetNode = $(event.target);
        const secondLevel = targetNode.closest('.toggle-second-level').next();

        $.each(this.toggleSecondLevelLinks, function () {
            jQuery(this).addClass('hide');
        });
        secondLevel.addClass('active');
    }

    toggleThirdLevel(event) {
        event.preventDefault();
        event.stopPropagation();
        const targetNode = $(event.target);
        const secondLevel = targetNode.closest('.mobile-navigation__container--second-level');
        const thirdLevel = targetNode.parent().next();

        $.each(this.toggleThirdLevelLinks, function () {
            $(this).addClass('hide');
        });

        $.each(this.secondLevelBackButtons, function () {
            $(this).addClass('hide');
        });

        secondLevel.removeClass('remove');
        thirdLevel.addClass('active');
    }


    handleSecondLevelBackButton(event) {
        event.preventDefault();
        event.stopPropagation();
        const targetNode = $(event.target);
        const secondLevel = targetNode.parent();

        $.each(this.toggleSecondLevelLinks, function () {
            $(this).removeClass('hide');
        });

        $.each(this.secondLevelBackButtons, function () {
            $(this).removeClass('hide');
        });

        secondLevel.removeClass('active');
    }

    handleThirdLevelBackButton(event) {
        event.preventDefault();
        event.stopPropagation();

        const targetNode = $(event.target);
        const thirdLevel = targetNode.parent();

        $.each(this.toggleThirdLevelLinks, function () {
            $(this).removeClass('hide');
        });

        $.each(this.toggleThirdLevelLinks, function () {
            $(this).removeClass('hide');
        });

        thirdLevel.removeClass('active');
    }

    openInSameTab(event) {
        event.preventDefault();
        event.stopPropagation();
        window.open($(event.currentTarget).attr('href'), '_self');
    }

    resetMobileNav() {
        $.each(this.toggleSecondLevelLinks, function () {
            jQuery(this).removeClass('hide');
        });
        $.each(this.secondLevelBackButtons, function () {
            $(this).addClass('hide');
        });
        $.each(this.thirdLevelBackButtons, function () {
            $(this).addClass('hide');
        });
        $.each(this.secondLevelContainer, function () {
            $(this).removeClass('active');
        });
        $.each(this.ThirdlevelContainer, function () {
            $(this).removeClass('active');
        });
    }
}
