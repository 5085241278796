'use strict';

var base = require('base/product/base');
var slickConfigs = require('../config/slickConfigs');
var zoomConfigs = require('lyonscg/config/zoomConfigs');
var imagesloaded = require('imagesloaded');
var utils = require('lyonscg/util/utils');
var productSetVariation = require('./productSetVariation');
var colorSelected;
/**
 * Disable PDP Zoom
 */
function disableZoom() {
    $('.slide-link').trigger('zoom.destroy');
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': '
                    + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    var html = '';

    promotions.forEach(function (promotion) {
        html += '<div class="callout" title="' + promotion.details + '">' + promotion.calloutMsg +
            '</div>';
    });

    return html;
}


/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability
        ? response.product.availability.messages : [];
    if (!response.product.readyToOrder) {
        availabilityValue = '<div>' + response.resources.info_selectforstock + '</div>';
    } else {
        availabilityMessages.forEach(function (message) {
            availabilityValue += '<div>' + message + '</div>';
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id);
    for (var i = 0, j = $defaultOption.length; i < j; i++) {
        $($defaultOption)[i].options[0].value = attr.resetUrl;
    }
    var colorName = colorSelected;
    var price = null;
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer
            .find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url)
            .removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });

    var $allCardWrappers = $('.product-overview__bundle-columns');

    var $cardWrapper = $allCardWrappers.filter(function () {
        return $(this).data('attr-id') === attr.attributeId;
    });

    var url = '';
    var value = '';
    var selected;
    var selectable;
    var title;
    var list;
    var atrIndex = 0; // eslint-disable-line no-unused-vars
    $cardWrapper.html('');
    attr.values.forEach(function (attrValue) {
        url = attrValue.url;
        value = attrValue.value;
        selected = attrValue.selected;
        title = attrValue.displayValue;
        selectable = attrValue.selectable;
        for (var a = 0, b = attr.variants.length; a < b; a++) {
            if (colorName === attr.variants[a].color && (value === attr.variants[a].size || value === attr.variants[a].videoQuality)) {
                price = attr.variants[a].price;
            }
        }
        if (price && price.list) {
            list = price.list.formatted;
        } else {
            list = null;
        }
        var $card = `
        <div class="product-overview__bundle-card ${selected === true ? 'active' : ''}">
            <div class="product-overview__bundle-name" data-value="${url}" data-attr-value="${url}" value="${value}">
                ${title}
            </div>
        </div>`;
        if (list === null) {
            $card = `
                <div class="product-overview__bundle-card ${selected === true ? 'active' : ''}">
                    <div class="product-overview__bundle-name" data-value="${url}" data-attr-value="${url}" value="${value}">
                        ${title}
                    </div>
                </div>`;
        }
        if (selectable === true) {
            $cardWrapper.append($card);
        }
        atrIndex++;
    });

    var $cards = $cardWrapper.find('.product-overview__bundle-card');

    $cards.on('click', (event) => {
        event.preventDefault();
        $(this).removeClass('active');
        const targetNode = event.currentTarget;
        var textContent = targetNode.children[0].dataset.value;
        targetNode.classList.add('active');
        $('.product-overview__attributes .custom-select-pdp option[value="' + textContent + '"]').prop('selected', true);
        var $selectionEl = $cardWrapper.parent().siblings('.product-overview__attributes .custom-select-pdp');
        $selectionEl.trigger('change');
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processSwatchValues(attr, $productContainer) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' +
            attrValue.value + '"]');
        var $swatchAnchor = $attrValue.parent().not('.plp-swatch');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            colorSelected = attrValue.value;
        } else {
            $attrValue.removeClass('selected');
        }

        if (attrValue.url) {
            $swatchAnchor.attr('data-href', attrValue.url);
        } else {
            $swatchAnchor.removeAttr('data-href');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttrs(attrs, $productContainer) {
    // Currently, the only attribute type that has image swatches is Color.
    var attrsWithSwatches = ['color'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts =
        $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item')
                .data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item')
                .data('ready-to-order', response.product.readyToOrder);
        } else {
            $productContainer.attr('data-pid', response.product.id);
        }
    }

    // Update primary images
    var primaryImageUrls = response.product.images;
    primaryImageUrls.pdp.forEach(function (imageUrl, idx) {
        $productContainer.find('.primary-images').find('img').eq(idx)
            .attr('src', imageUrl.url);
    });

    // Update pricing if not a set, for set there is a different script
    if (response.product.productType !== 'set') {
        if (!isChoiceOfBonusProducts) {
            var $priceSelector = $('.prices .price', $productContainer).length
                ? $('.prices .price', $productContainer)
                : $('.prices .price');
            $priceSelector.replaceWith(response.product.price.html);
        }
    }

    // Update promotions
    $('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty()
        .html(getAttributesHtml(response.product.attributes));
}

/**
 * Init PDP Zoom
 */
function initZoom() {
    disableZoom();

    var isDesktop = utils.mediaBreakpointUp('lg');
    var $activeSlide = $('.product-carousel .slick-active');
    var $image = $activeSlide.find('.slide-link.zoom-hires');
    var url = $image.attr('href');

    if ($image.length > 0 && url && url !== 'null' && isDesktop) {
        // Start spinner while zoom image loads
        $activeSlide.spinner().start();

        var config = {
            url: url,
            callback: function () {
                // Stop spinner when zoom image loaded
                $activeSlide.spinner().stop();
            }
        };
        config = $.extend({}, zoomConfigs, config);

        $image.zoom(config);
    }
}

/**
 * Init the product carousel using a predefined slick configuration
 */
function carouselInit() {
    var $carousel = $('.product-carousel');

    if ($carousel.length) {
        imagesloaded($carousel).on('done', function () {
            if ($carousel.hasClass('slick-initialized')) {
                $carousel.off('init', initZoom);
                $carousel.off('afterChange', initZoom);
            }
            $carousel.on('init', initZoom);
            $carousel.on('afterChange', initZoom);
            $carousel.not('.slick-initialized').slick(slickConfigs.pdp);
        });
    }
}

/**
 * Init the price spider
 */
function initPricespider() {
    window.onload = function () {
        if (typeof window.PriceSpider !== 'undefined' && typeof window.PriceSpider.rebind === 'function') {
            window.PriceSpider.rebind();
        }
    };
}

/**
 * Deconstruct (unslick) the carousel, removing classes and handlers added on slick initialize.
 */
function carouselUnslick() {
    var $carousel = $('.product-carousel');

    if ($carousel.length && $carousel.hasClass('slick-initialized')) {
        $carousel.off('init', initZoom);
        $carousel.off('afterChange', initZoom);
        $carousel.slick('unslick');
    }
}

/**
 * Init the product carousel using a predefined slick configuration for bonus item
 */
function carouselInitBonus() {
    var $carouselBonus = $('.bonus-product-item');
    if ($carouselBonus.length) {
        $carouselBonus.each(function () {
            var $carouselBonusDiv = $(this).find('.product-carousel');
            if ($carouselBonusDiv.length) {
                imagesloaded($carouselBonusDiv).on('done', function () {
                    $carouselBonusDiv.on('init', initZoom);
                    $carouselBonusDiv.on('afterChange', initZoom);
                    $carouselBonusDiv.not('.slick-initialized').slick(slickConfigs.pdp);
                });
            }
        });
    }
}

/**
 * Deconstruct (unslick) the carousel, removing classes and handlers added on slick initialize.
 */
function carouselUnslickBonus() {
    var $carouselBonus = $('.bonus-product-item');
    if ($carouselBonus.length) {
        $carouselBonus.each(function () {
            var $carouselBonusDiv = $(this).find('.product-carousel');
            if ($carouselBonusDiv.length && $carouselBonusDiv.hasClass('slick-initialized')) {
                $carouselBonusDiv.off('init', initZoom);
                $carouselBonusDiv.off('afterChange', initZoom);
                $carouselBonusDiv.slick('unslick');
            }
        });
    }
}
/**
 * @param  {json} product - Product json
 * @param {boolean} plp - is plp tile
 * @returns {string} htmlString  - carousel slides
 */
function updateMainImages(product, plp) {
    var images = product.images;
    var htmlString = '<!-- Product Image slides -->';
    var video = '';

    if (plp) {
        var htmlSlider = '<source media="(min-width: 1200px)" srcset="' + images.large[0].url.replace(/\s/g, '%20') + ' 1x, ' + images.largeRetina[0].url.replace(/\s/g, '%20') + ' 2x">' +
            '<source media="(min-width: 768px)" srcset="' + images.largeTablet[0].url.replace(/\s/g, '%20') + ' 1x, ' + images.largeTabletRetina[0].url.replace(/\s/g, '%20') + ' 2x">' +
            '<source media="(min-width: 375px)" srcset="' + images.largeTablet[0].url.replace(/\s/g, '%20') + ' 1x,' + images.largeTabletRetina[0].url.replace(/\s/g, '%20') + ' 2x">' +
            '<source media="(min-width: 270px)" srcset="' + images.largeMobile[0].url.replace(/\s/g, '%20') + ' 1x,' + images.largeMobileRetina[0].url.replace(/\s/g, '%20') + ' 2x">' +
            '<img class="tile-image ' + (product.images.large.length > 1 ? 'tile-image-original' : ' ') + '"' +
            'src="' + (product.selectedProductUrl || images.large[0].url) + '" class="slide-img" alt="' + images.large[0].alt + '" title="' + images.large[0].title + '" data-media itemprop="image"/>';

        htmlString += htmlSlider;
    } else {
        if (product.cdnVideo !== null && (product.productType === 'master' || product.productType === 'variant')) {
            video = '<div class="product-overview__gallery-slide video-slide"><video data-media-type="video" class="marquee__media" id="pdp-featured-video" muted="" playsinline="" autoplay loop>' +
                '<source src="' + product.cdnVideo + '">' +
                'Your browser does not support the video tag.' +
            '</video></div>';
            htmlString += video;
        }
        images.pdp.forEach(function (image, idx) {
            var htmlSlide = '<div class="product-overview__gallery-slide">' +
                '<picture>' +
                '<source media="(min-width: 840px)" srcset="' + images.pdpLrgDesktop[idx].url.replace(/\s/g, '%20') + ' 1x, ' + images.pdpLrgDesktopRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 768px)" srcset="' + images.pdp[idx].url.replace(/\s/g, '%20') + ' 1x, ' + images.pdpRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 375px)" srcset="' + images.pdpTablet[idx].url.replace(/\s/g, '%20') + ' 1x,' + images.pdpTabletRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 270px)" srcset="' + images.pdpMobile[idx].url.replace(/\s/g, '%20') + ' 1x,' + images.pdpMobileRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<img src="' + image.url + '" class="slide-img" alt="' + image.alt + '" />' +
                '</div>';
            htmlString += htmlSlide;
        });
        images.carouselLifestyle.forEach(function (image, idx) {
            var htmlSlide = '<div class="product-overview__gallery-slide lifestyle-slide">' +
                '<picture>' +
                '<source media="(min-width: 840px)" srcset="' + images.carouselLifestyleLrgDesktop[idx].url.replace(/\s/g, '%20') + ' 1x, ' + images.carouselLifestyleLrgDesktopRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 768px)" srcset="' + images.carouselLifestyle[idx].url.replace(/\s/g, '%20') + ' 1x, ' + images.carouselLifestyleRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 375px)" srcset="' + images.carouselLifestyleTablet[idx].url.replace(/\s/g, '%20') + ' 1x,' + images.carouselLifestyleTabletRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 270px)" srcset="' + images.carouselLifestyleMobile[idx].url.replace(/\s/g, '%20') + ' 1x,' + images.carouselLifestyleMobileRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<img src="' + image.url + '" class="slide-img" alt="' + image.alt + '" />' +
                '</div>';
            htmlString += htmlSlide;
        });
    } /* else {
        if (product.cdnVideo !== null && (product.productType === 'master' || product.productType === 'variant')) {
            video = '<video data-media-type="video" class="marquee__media" id="pdp-featured-video" muted="" playsinline="" autoplay loop>' +
                '<source src="' + product.cdnVideo + '">' +
                'Your browser does not support the video tag.' +
            '</video>';
            htmlString += video;
        }
        images.pdp.forEach(function (image, idx) {
            var htmlSlide = '<div class="product-overview__gallery-slide">' +
                '<picture>' +
                '<source media="(min-width: 840px)" srcset="' + images.pdpLrgDesktop[idx].url.replace(/\s/g, '%20') + ' 1x, ' + images.pdpLrgDesktopRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 768px)" srcset="' + images.pdp[idx].url.replace(/\s/g, '%20') + ' 1x, ' + images.pdpRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 375px)" srcset="' + images.pdpTablet[idx].url.replace(/\s/g, '%20') + ' 1x,' + images.pdpTabletRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<source media="(min-width: 270px)" srcset="' + images.pdpMobile[idx].url.replace(/\s/g, '%20') + ' 1x,' + images.pdpMobileRetina[idx].url.replace(/\s/g, '%20') + ' 2x">' +
                '<img src="' + image.url + '" class="slide-img" alt="' + image.alt + '" />' +
                '</div>';
            htmlString += htmlSlide;
        });
    } */
    return htmlString;
}

/**
 * @param  {json} product - Product json
 * @returns {string} htmlString  - carousel slides
 */
function updateThumbnailImages(product) {
    var images = product.images;
    var htmlString = '<!-- Product Thumbnail slides -->';
    images.carouselVideoThumbnail.forEach(function (image) {
        var htmlSlide = '<div class="product-overview__gallery-slide thumbnail-slide video-thumbnail-slide">' +
            '<img src="' + image.url + '" class="slide-img" alt="' + image.alt + '" />' +
            '</div>';
        htmlString += htmlSlide;
    });
    images.carouselThumbnail.forEach(function (image) {
        var htmlSlide = '<div class="product-overview__gallery-slide thumbnail-slide">' +
            '<img src="' + image.url + '" class="slide-img" alt="' + image.alt + '" />' +
            '</div>';
        htmlString += htmlSlide;
    });
    images.carouselLifestyleThumbnail.forEach(function (image) {
        var htmlSlide = '<div class="product-overview__gallery-slide thumbnail-slide video-thumbnail-slide">' +
            '<img src="' + image.url + '" class="slide-img" alt="' + image.alt + '" />' +
            '</div>';
        htmlString += htmlSlide;
    });
    return htmlString;
}

/**
*@param {Object} response - response from Ajax call
*@param {jQuery} $productContainer - DOM element for a given product.
 */
function updateBundlePrice(response, $productContainer) {
    var $priceSelector = $('.product-bundle .prices .price', $productContainer).length
        ? $('.product-bundle .prices .price', $productContainer)
        : $('.product-bundle .prices .price');
    $priceSelector.replaceWith(response.product.price.html);
}

/**
 * Updates the full price of a product set on PLP when a color swatch is selected
 *
 * @param {jQuery} $priceEl - The price element to update
 * @param {string} price - The price text to set
 * @param {boolean} discount - Whether the price has a discount
 * @param {string} productType - The type of a product on a tile
 * @returns {void}
 */
function updatePlpFullPrice($priceEl, price, discount, productType) {
    var $fullPriceContainer = productType === 'set'
        ? $priceEl.find('.js-set-price-plp_full')
        : $priceEl.find('.js-product-price-plp_full');

    if ($fullPriceContainer.length > 1) {
        $fullPriceContainer.eq(1).detach();
        $fullPriceContainer = $($fullPriceContainer[0]);
    }

    if (discount) {
        $fullPriceContainer.addClass('strike-through list');
    } else {
        $fullPriceContainer.removeClass('strike-through list');
    }

    if (productType === 'set') {
        $fullPriceContainer.contents().last().replaceWith(price);
    } else {
        $fullPriceContainer.contents().eq(1).replaceWith(price);
    }
}

/**
 * Updates the discount price of a product set on PLP when a color swatch is selected
 *
 * @param {jQuery} $priceEl - The price element to update
 * @param {string} priceTxt - The price text to set
 * @param {string|null} promo - The promo discount price
 * @param {string} productType - The type of a product on a tile
 * @returns {void}
 */
function updatePlpDiscountPrice($priceEl, priceTxt, promo, productType) {
    var $salePrice = $priceEl.find('.js-set-price-sales__plp-wrapper_sales-price');
    var $outerSpanSalePrice = $('<span></span>').addClass(`${promo ? 'starting strike-through list price-mid-val' : 'sales red-color'} js-set-price-sales__plp-wrapper_sales-price`);
    var $innerSpanSalePrice = $('<span></span>')
        .addClass('value large-text')
        .attr('itemprop', 'price')
        .attr('content', priceTxt)
        .text(priceTxt);

    var $innerSpanPromoPrice = $('<span></span>')
        .addClass('value large-text')
        .attr('itemprop', 'price')
        .attr('content', promo)
        .text(promo);

    var $fullPriceEl = productType === 'set'
                ? $priceEl.find('.js-set-price-plp_full')
                : $priceEl.find('.js-product-price-plp_full');

    $salePrice.remove();
    $outerSpanSalePrice.append($innerSpanSalePrice);
    $outerSpanSalePrice.insertAfter($fullPriceEl);

    if (promo) {
        $priceEl.find('.js-set-price-sales__plp-wrapper_promo-discount').remove();

        var $promoOuterSpan = $('<span></span>').addClass('sales white-color bold700 js-set-price-sales__plp-wrapper_promo-discount');

        $promoOuterSpan.append($innerSpanPromoPrice);
        $promoOuterSpan.insertAfter($outerSpanSalePrice);
    }
}

/**
 * Re-renders the save amount percent text
 *
 * @param {jQuery} $priceEl - The price DOM container
 * @param {string} saveAmount - The text of the save amount percent
 */
function updateDiscountPercent($priceEl, saveAmount) {
    var $saveAmountEl = $priceEl.find('.js-save-amount');

    if ($saveAmountEl.length) {
        $saveAmountEl.removeClass('d-none');
        $saveAmountEl.text(saveAmount);
    } else {
        var $newSaveAmount = $('<span>', {
            class: 'js-save-amount red-color text-danger value large-text',
            text: saveAmount
        });

        $priceEl.append($newSaveAmount);
    }
}

/**
* @param {Object} response - response from Ajax call
* @param {jQuery|null} $hitColorElOnPlp - DOM element of a color swatch on PLP or null if the method is executed not on PLP
 */
function updatePrice(response, $hitColorElOnPlp) {
    if (!$hitColorElOnPlp) {
        return;
    }

    var $priceEl = $hitColorElOnPlp.closest('.js-product-category__list-item').find('.js-set-price-plp');
    var productType = response.product.productType;
    var fullPriceTxt;
    var discountPriceTxt;
    var promoDiscountPriceTxt;
    var saveAmount;

    if (productType === 'set') {
        fullPriceTxt = response.product.price.availableProductsSetPrice.formattedFull;
        discountPriceTxt = response.product.price.availableProductsSetPrice.formattedDisc;
        promoDiscountPriceTxt = response.product.price.availableProductsSetPrice.formattedPromoDisc;
        saveAmount = response.product.price.availableProductsSetPrice.formattedSavePercent;
    } else {
        fullPriceTxt = response.product.price.list && response.product.price.list.formatted;
        discountPriceTxt = response.product.price.sales && response.product.price.sales.formatted;
        promoDiscountPriceTxt = response.product.price.promo && response.product.price.promo.formatted;
        saveAmount = response.product.price.productDiscountPercentage;
    }

    if (fullPriceTxt && discountPriceTxt && promoDiscountPriceTxt) {
        updatePlpFullPrice($priceEl, fullPriceTxt, true, productType);
        updatePlpDiscountPrice($priceEl, discountPriceTxt, promoDiscountPriceTxt, productType);
    } else if ((fullPriceTxt && discountPriceTxt) || (discountPriceTxt && promoDiscountPriceTxt)) {
        var higherPrice = fullPriceTxt || discountPriceTxt;
        var lowerPrice = promoDiscountPriceTxt || discountPriceTxt;

        updatePlpFullPrice($priceEl, higherPrice, true, productType);
        updatePlpDiscountPrice($priceEl, lowerPrice, null, productType);
        $priceEl.find('.js-set-price-sales__plp-wrapper_promo-discount').remove();
    } else if (fullPriceTxt || discountPriceTxt) {
        var price = fullPriceTxt || discountPriceTxt;

        updatePlpFullPrice($priceEl, price, false, productType);
        $priceEl.find('.js-set-price-sales__plp-wrapper_sales-price').remove();
        $priceEl.find('.js-set-price-sales__plp-wrapper_promo-discount').remove();
    }

    if (saveAmount) {
        updateDiscountPercent($priceEl, saveAmount);
    } else {
        $priceEl.find('.js-save-amount').remove();
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 * @param {jQuery} $hitColorEl - The hit color swatch element
 */
function attributeSelect(selectedValueUrl, $productContainer, $hitColorEl) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                if (
                    ($productContainer.hasClass('product-grid') && $productContainer.find('.product-category__list-item').length) ||
                    $productContainer.hasClass('slick-slide')
                ) {
                    var updateMainImagesHTML = updateMainImages(data.product, true);

                    var pid = data.product.masterProductID || data.product.id;
                    var listItem = $hitColorEl.closest('.product-category__list-item[id="' + pid + '"]');

                    if (listItem && listItem.length) {
                        var title = data.product.displayName || data.product.productName;
                        var subtitle = data.product.productHeadingDescStr || data.product.shortDescription;
                        var navUrl = data.product.productNavigationUrl || data.product.selectedProductUrl;

                        listItem.find('picture').empty().html(updateMainImagesHTML);
                        listItem.find('.image-container a.plp-main-image').attr('href', navUrl);
                        listItem.find('.product-category__cta-section a').attr('href', navUrl);
                        listItem.find('.js-search-tile-product-name h4').attr('title', title).text(title).addClass('product-heading-title');
                        listItem.find('.js-plp-tile__item-attributes_text').html(subtitle).addClass('product-heading-description');
                    }

                    listItem.find('.variant-section .variant-border').removeClass('light');
                    let selectedColor = data.product.selectedColor || '';

                    if (data.product.variationAttributes) {
                        let colorObject = data.product.variationAttributes.filter((variationAttribute) => {
                            return variationAttribute.attributeId === 'color';
                        });

                        if (colorObject.length) {
                            let color = colorObject[0].values.filter((value) => {
                                return value.selected;
                            });
                            if (color.length) {
                                selectedColor = color[0].id;
                            }
                        }
                    }

                    listItem.find('.variant-section .' + selectedColor).addClass('light');

                    var productId = data.product.masterProduct ? data.product.masterProductID : data.product.id;
                    listItem.attr('id', productId);

                    updatePrice(data, $hitColorEl);

                    return;
                }

                updatePrice(data, $productContainer);
                handleVariantResponse(data, $productContainer);

                base.updateOptions(data.product.options, $productContainer);
                base.updateQuantities(data.product.quantities, $productContainer);

                if ($('.select-attr.custom-select').children('option:first-child').is(':selected')) {
                    $('.cart-and-ipay').hide();
                } else {
                    $('.cart-and-ipay').show();
                }

                if ($('.js-affirm-pdp-promo').length > 0) {
                    if (data.product.availability.isPreorder === true || data.product.availability.isBackorder === true) {
                        if ($('.js-affirm-pdp-preorder-filter').length > 0 && $('.js-affirm-pdp-preorder-filter').val() === 'true') {
                            $('.js-affirm-pdp-promo').addClass('d-none');
                        } else {
                            $('.js-affirm-pdp-promo').removeClass('d-none');
                        }
                    } else {
                        $('.js-affirm-pdp-promo').removeClass('d-none');
                        $('.js-pdp-affirmprice').removeClass('d-none');
                    }

                    if (!data.product.isAffirmThresholdReached) {
                        $('.js-affirm-pdp-promo').addClass('d-none');
                    } else if (data.product.isAffirmThresholdReached) {
                        $('.js-affirm-pdp-promo').removeClass('d-none');
                        $('.js-pdp-affirmprice').removeClass('d-none');
                    }
                    var affirmSubtotal = 0;
                    if (data.product) {
                        affirmSubtotal = 0;
                        if (data.product.price && data.product.price.sales) {
                            affirmSubtotal = data.product.price.sales.value;
                        }
                    }
                    if ($('.js-pdp-affirmprice').length > 0) {
                        $('.js-pdp-affirmprice').attr('data-amount', affirmSubtotal * 100);
                    }
                    if (typeof affirm !== 'undefined') { // eslint-disable-line
                        affirm.ui.ready(function() { // eslint-disable-line
                            affirm.ui.refresh(); // eslint-disable-line
                        });
                    }
                }

                if (data.product.hideCTAAndAffirm === false) {
                    $('.product-nav__right').removeClass('hide-cta-and-affirm');
                    $('.js-pdp-a2c-buttons .cart-and-ipay').removeClass('hide-cta-and-affirm');
                } else if (data.product.hideCTAAndAffirm === true) {
                    $('.product-nav__right').addClass('hide-cta-and-affirm');
                    $('.js-pdp-a2c-buttons .cart-and-ipay').addClass('hide-cta-and-affirm');
                }
                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
                $.spinner().stop();
                // Change price spider skus after variant select in non-ecom websites
                if ($('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').length === 0) {
                    $('.price-spider').each(function () {
                        if (data.product.hideFindARetailer !== null && !data.product.hideFindARetailer) {
                            $(this).replaceWith('<div class="ps-nsosellers disable price-spider" ps-button-label="Find A Retailer">Find A Retailer</div> ');
                            $('.price-spider').html('');
                            $('.price-spider').removeClass('ps-nsosellers');
                            $('.price-spider').removeClass('d-none');
                            $('.price-spider').removeClass('disable').addClass('ps-widget');
                            $('.price-spider').attr('ps-sku', data.product.id);
                            if (window.PriceSpider) {
                                window.PriceSpider.rebind();
                            }
                        } else {
                            $(this).replaceWith('<div class="ps-nsosellers disable price-spider d-none" ps-button-label="Find A Retailer">Find A Retailer </div> ');
                        }
                    });
                }
                // Update non-ecom retailers when available
                if ($('.js-non-ecom-retailerbody').length > 0) {
                    if (data.product.nonecomretailers !== null) {
                        $('.js-non-ecom-retailerbody .modal-body .row').html(decodeURIComponent(data.product.nonecomretailers));
                    }
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
}

/**
 * Function to get variant specific up-sell recommendations
 * @param {string} productID ID of the product added to cart
 */
function generateUpsellData(productID) {
    var recommURL = $('.flyout-cart__recommendation').data('url') + productID;
    $('.flyout-cart__modal').spinner().start();
    $('.flyout-cart__recommendation').empty();
    $('.flyout-cart__recommendation').load(recommURL, function () {
        var config = {
            centerMode: false,
            variableWidth: true,
            infinite: false,
            draggable: true,
            arrows: true,
            dots: false,
            slidesToScroll: 1,
            slidesToShow: 4.1,
            rows: 0,
            responsive: [
                {
                    breakpoint: 1181,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 415,
                    settings: {
                        slidesToShow: 2,
                        arrows: false,
                        dots: true
                    }
                }
            ]
        };
        var theme = $(this).data('slick-slider-theme') === 'light' ? 'light' : 'dark';
        $(this).addClass(theme);
        if ($('.flyout-cart__recommendation-slider').length > 0) {
            $('.no-rec').each(function () {
                $(this).removeClass('no-rec');
            });
            $('.flyout-cart__recommendation-slider').not('.slick-initialized').slick(config);
        }
        $.spinner().stop();
        $('#flyout-cart').addClass('modal--opened');
    });
}

var exportBase = $.extend({}, base, {
    carouselInit: carouselInit,
    carouselInitBonus: carouselInitBonus,
    carouselUnslick: carouselUnslick,
    carouselUnslickBonus: carouselUnslickBonus,
    updateMainImages: updateMainImages,
    updateThumbnailImages: updateThumbnailImages,
    processNonSwatchValues: processNonSwatchValues,
    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            if ($(this).attr('id') || $(this).attr('id') === 'sel-sort' || $(this).attr('id') === 'selector-1' || $(this).attr('id') === 'selector-2' || $(this).attr('id') === 'selector-3') {
                // Do nothing
            } else {
                attributeSelect(e.currentTarget.value, $productContainer);
            }
        });
        $(document).ready(function () {
            initPricespider();
        });
    },
    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] a', function (e) {
            e.preventDefault();

            var $hitColorEl = $(this);

            if ($hitColorEl.attr('disabled')) {
                return;
            }

            if ($hitColorEl.hasClass('js-color-attribute__product-set')) {
                var itemData = $hitColorEl.parent().data('obj');
                var id = itemData.id;
                var url = $hitColorEl.closest('.js-variant-section').data('url-product-show');

                productSetVariation.methods.renderVariantsOnPdp(id, url);
                productSetVariation.methods.updateColorSwatch($hitColorEl);
                productSetVariation.methods.updateBasketInformation($hitColorEl);
                productSetVariation.methods.updateProductNames($hitColorEl.data('display-names'));

                return;
            }

            var $productContainer = $hitColorEl.closest('.set-item');

            if (!$productContainer.length) {
                $productContainer = $hitColorEl.closest('.slick-slide');
            }

            if (!$productContainer.length) {
                $productContainer = $hitColorEl.closest('.product-category__list');
            }

            if (!$productContainer.length) {
                $productContainer = $hitColorEl.closest('.product-detail');
            }

            attributeSelect($hitColorEl.attr('data-href'), $productContainer, $hitColorEl);
        });
    },
    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();
            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }
            if ($('.bundle-items', $productContainer).length !== 0) {
                $.ajax({
                    url: $(e.currentTarget).find('option:selected').data('url'),
                    method: 'GET',
                    success: function (data) {
                        updateBundlePrice(data, $productContainer);
                        $.spinner.stop();
                    },
                    error: function () {
                        $.spinner.stop();
                    }
                });
            } else {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                    $productContainer);
            }
        });
    },
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var that = this;

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.js-product-set-detail').data('is-product-set') && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                $('.js-set-item').each(function () {
                    var $productCont = $(this);
                    if (!$productCont.hasClass('product-set-detail') && $productCont.data('available')) {
                        setPids.push({
                            pid: $productCont.data('pid'),
                            qty: $productCont.data('qty') || 1,
                            options: base.getOptions($productCont)
                        });
                    }
                });

                pidsObj = JSON.stringify(setPids);
                pid = $(this).data('pid');
            } else {
                pid = base.getPidValue($(this));
            }

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
            }
            var subsriptionProductID = $productContainer.find('input[name="subscriptionProduct"]:checked').val();

            addToCartUrl = $('.add-to-cart-url').val();

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: base.getChildProducts(),
                quantity: base.getQuantitySelected($(this)),
                subsriptionProductID: subsriptionProductID
            };

            if (!$('.bundle-item').length) {
                form.options = base.getOptions($productContainer);
            }

            $(this).trigger('updateAddToCartFormData', form);
            /* replacement fields from response */
            var topName = '';
            var bottomName = '';
            var kitName = '';
            var imageURL = '';
            var imageAlt = '';
            var pricing = '';
            var itemQty = '';
            var subTotal = '';
            var avail = '';
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        $(that).blur();
                        if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                            var cart = {};
                            cart = data.cart.trackCartDatalayer;
                            _etmc.trackCart([{cart}]); // eslint-disable-line
                        }

                        var isProductSet = data.productAdded.isProductSet;

                        // base.handlePostCartAdd(data);
                        $('.minicart').trigger('count:update', data);
                        var messageType = data.error ? 'alert-danger' : 'alert-success';
                        // show add to cart toast
                        if (data.newBonusDiscountLineItem
                            && Object.keys(data.newBonusDiscountLineItem).length !== 0) {
                            base.methods.editBonusProducts(data.newBonusDiscountLineItem);
                        } else {
                            var hasUpsell = document.querySelector('.flyout-cart__recommendation');

                            if (($('.add-to-cart-messages').length === 0 && !hasUpsell) || isProductSet) {
                                $('body').append(
                                '<div class="add-to-cart-messages"></div>'
                                );
                            }

                            if (!!data.atsBundleRemoved && data.atsBundleRemoved === true) {
                                $('.js-ats-bundle-removed').removeClass('d-none');
                                $('.js-ats-bundle-removed').addClass('d-block');
                                $('#atsRemovedModal').modal('show');
                            } else {
                                $('.add-to-cart-messages').append(
                                    '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                                    + data.message
                                    + '</div>'
                                );
                                setTimeout(function () {
                                    $('.add-to-basket-alert').remove();
                                }, 5000);
                            }
                            var productID = data.productAdded.pid;
                            if (!data.error) {
                                if (!isProductSet && $('#flyout-cart').length && hasUpsell) {
                                    $('body').addClass('no-scroll');
                                    generateUpsellData(productID);
                                }
                                for (var i = 0, j = data.cart.items.length; i < j; i++) {
                                    if (data.cart.items[i].id === productID) {
                                        var productAdded = data.cart.items[i];
                                        avail = (productAdded.availability.isPreorder || productAdded.availability.isBackorder) ? productAdded.availability.inStockDate : '';
                                        topName = productAdded.topLine !== null ? productAdded.topLine : productAdded.productName;
                                        bottomName = productAdded.bottomLine !== null ? productAdded.bottomLine : '';
                                        if (productAdded.variationAttributes !== null) {
                                            kitName = (typeof productAdded.variationAttributes[1] !== 'undefined') ? productAdded.variationAttributes[1].displayValue : '';
                                            if (productAdded.variationAttributes[0] !== null) {
                                                if (typeof productAdded.variationAttributes[1] !== 'undefined') {
                                                    kitName += ' - ';
                                                }
                                                kitName += (typeof productAdded.variationAttributes[0] !== 'undefined') ? productAdded.variationAttributes[0].displayValue : '';
                                            }
                                        }
                                        imageURL = productAdded.images.pdpLrgDesktop[0].url;
                                        imageAlt = productAdded.images.pdpLrgDesktop[0].alt;
                                        if (productAdded.price.list) {
                                            var discountPrice = productAdded.price.sales.formatted;
                                            pricing = productAdded.price.list.formatted;
                                            $('.flyout-cart__product-price del').show();
                                            $('.flyout-cart__product-price del>span.list').addClass('strike-through');
                                            $('.flyout-cart__product-price del').css('text-decoration', 'line-through');
                                            $('.flyout-cart__product-price .value').html(pricing);
                                            $('.reg-flyout-price').html(discountPrice);
                                        } else {
                                            pricing = productAdded.price.sales.formatted;
                                            $('.flyout-cart__product-price del>span.list').removeClass('strike-through');
                                            $('.flyout-cart__product-price del').css('text-decoration', 'none');
                                            $('.flyout-cart__product-price .list').html(pricing);
                                            $('.reg-flyout-price').html('');
                                        }
                                        pricing = productAdded.price.sales.formatted;
                                        itemQty = data.quantityTotal;
                                        subTotal = data.cart.totals.grandTotal;
                                        if (avail !== '') {
                                            $('.flyout-cart__product-shipping').show();
                                            $('.ships-on-date').html(avail);
                                        } else {
                                            $('.flyout-cart__product-shipping').hide();
                                        }
                                        $('.flyout-cart__product-name').html(topName);
                                        $('.flyout-cart__product-attributes').html(bottomName);
                                        $('.flyout-cart__product-addon').html(kitName);
                                        $('.flyout-cart__product-image').attr('src', imageURL);
                                        $('.flyout-cart__product-image').attr('alt', imageAlt);
                                        $('.qty-flyout-total').html(itemQty);
                                        $('.subtotal-flyout').html(subTotal);
                                        if (data.atsBundleRemoved === true) {
                                            $('.minicart_wrap').removeClass('express-checkout');
                                            $('.js-ats-bundle-removed').removeClass('d-none');
                                            $('.js-ats-bundle-removed').addClass('d-block');
                                        }
                                    }
                                }
                            } else {
                                if ($('.add-to-cart-messages').length === 0) {
                                    $('body').append(
                                    '<div class="add-to-cart-messages"></div>'
                                    );
                                } else {
                                    $('.add-to-cart-messages').html('');
                                }
                                $('.add-to-cart-messages').append(
                                    '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
                                    + data.message
                                    + '</div>'
                                );
                            }
                        }
                        $('.minicart_wrap').children().remove();
                        $('body').trigger('product:afterAddToCart', data);
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    }
});

module.exports = exportBase;
