'use strict';

/**
 * Reusable slick carousel configurations
 * @example - $('.product-carousel').slick(slickConfigs.pdp)
 */

module.exports = {
    hero: {
        autoplay: true,
        autoplaySpeed: 5000,
        easing: 'swing',
        infinite: false,
        speed: 800,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    },

    productTiles: {
        infinite: false,
        speed: 300,
        dots: false,
        arrows: true,
        slidesToShow: 3.1,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 1.5,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    },

    pdp: {
        // infinite: false,
        // speed: 400,
        dots: true,
        arrows: true// ,
        // slidesToShow: 1,
    },

    pdpNew: {
        asNavFor: '[data-product-thumbnail-slider]',
        centerMode: false,
        variableWidth: false,
        infinite: true,
        draggable: false,
        arrows: true,
        dots: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        rows: 0,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: true
                }
            }
        ]
    },

    pdpThumbnails: {
        asNavFor: '[data-product-media-slider]',
        centerMode: false,
        slidesToShow: $('.product-overview__gallery-thumbnail ').find('img').length,
        infinite: true,
        vertical: true,
        draggable: false,
        arrows: false,
        dots: false,
        focusOnSelect: true,
        slidesToScroll: 1,
        rows: 0
    },
    privacySlider: {
        dots: true,
        arrows: false,
        settings: 'unslick',
        responsive: [{
            breakpoint: 960
        }]
    }
};
