'use strict';

var SFMCErrorHandler = require('../thirdParty/sfmcErrorHandler');

/**
 * @name findEmailSubscriptionError
 * @description find out the error msg for email
 * subscription based on error code and control
 * @param {string} errorcode input param
 * @param {string} errorcontrol input param
 * @returns {string} error message
 */
function findEmailSubscriptionError(errorcode, errorcontrol) {
    var form = 'footer';
    var error = SFMCErrorHandler.getEmailSubscriptionError(errorcode, errorcontrol, form);
    return error;
}

$(document).ready(function () {
    $('#regionSelect').select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'below',
        width: '100%'
    });

    $('.footer__newsletter__result').hide();

    var newsletterInput = document.querySelector('.footer__newsletter-form input.sfmc-nl');
    var newsletterButton = document.querySelector('.footer__newsletter-form button.sfmc-nl-btn');
    var newsletterDesktopPlaceholder = document.querySelector('.footer__newsletter-placeholder');

    if (newsletterInput) {
        newsletterInput.addEventListener('input', () => {
            if (newsletterInput.value) {
                newsletterButton.disabled = false;
                newsletterDesktopPlaceholder.style.display = 'none';
            } else {
                newsletterButton.disabled = true;
                newsletterDesktopPlaceholder.style.display = '';
            }
        });
    }

    var pattern = /^\w+([-+.'][^\s]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    $(newsletterButton).off('click').on('click', function () {
        var email = $(newsletterInput).val();
        if (!pattern.test(email)) {
            $('.footer__newsletter__result').hide();
            $('.footer__newsletter__result__invalid').show();
        }
    });

    var url = window.location.href.split('?')[0];
    var urlParams = new URLSearchParams(window.location.search);
    var signupSuccessUrl = $('#footer-email-signup-success');
    var signupErrorUrl = $('#footer-email-signup-error');
    var pageUrl = new URL(window.location).toString();
    // if an error occurs the hash that hooks up to the anchor is buried in the parameter string
    // this code moves the hash back to the end of the URL and reloads the page.
    var hash = '#above-footer';
    var hasNewsletter = urlParams.has('newsletter_signup');
    if (hasNewsletter === true) {
        if (!pageUrl.endsWith(hash)) {
            if (pageUrl.includes(hash)) {
                pageUrl = pageUrl.replace(hash, '') + hash;
                window.location.href = pageUrl;
            }
        }
    }
    if (signupSuccessUrl) {
        $(signupSuccessUrl).val(url + '?newsletter_signup=success' + hash);
    }

    if (signupErrorUrl) {
        $(signupErrorUrl).val(url + '?newsletter_signup=error' + hash);
    }

    var anyMsg = urlParams.get('newsletter_signup');
    if (anyMsg === 'success' || anyMsg === 'error') {
        $('.footer__newsletter__result').hide();
        if (anyMsg === 'success') {
            $('.footer__newsletter__result__success').show();
        } else {
            var errorcode = urlParams.has('errorcode') ? urlParams.get('errorcode') : null;
            var errorcontrol = urlParams.has('errorcontrol') ? urlParams.get('errorcontrol') : null;
            var errorMsg = findEmailSubscriptionError(errorcode, errorcontrol);
            $('.footer__newsletter__result__error').text(errorMsg).show();
        }
    }
    setTimeout(() => {
        if ($('.footer__panel-heading').length) {
            $('.footer__panel-heading button').eq(0).attr('aria-expanded', 'false');
            $('.footer__panel-heading button').eq(0).attr('class', 'collapsed');
            $('.footer__panel-heading').eq(0).click();
        }
    }, 2000);
});
