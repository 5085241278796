'use strict';

/* global pageContext */
var dataLayer = window.dataLayer;

/* global dataLayer */

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    account: function () {},
    cart: function () {
        // Assign Product Obj when remove from cart click for modal confirmation
        $(document).on('click', '.hidden-md-down .remove-product', function () {
            var productObj = $.parseJSON($(this).attr('data-gtmdata'));
            var currency = $(this).attr('data-currency');
            // Send removeFromCart event when confirmation is complete
            $(document).on('click', '.cart-delete-confirmation-btn', function () {
                removeFromCart(productObj, getQuantityValue(productObj), currency);
            });
        });
        $('.cart-page .remove-btn.remove-product').on('click', function () {
            var productObj = $.parseJSON($(this).attr('data-gtmdata'));
            var currency = $(this).attr('data-currency');
            removeFromCart((productObj), getQuantityValue(productObj), currency);
        });
    },
    checkout: function () {
        $(document).on('click', '.shipping-summary .edit-button', function () {
            updateCheckoutStep(3);
        });
        $(document).on('click', '.submit-shipping', function () {
            updateCheckoutStep(4);
        });
        $(document).on('click', '.validate-shipping', function () {
            updateCheckoutStep(4);
        });

        $(document).on('click', '.submit-payment', function () {
            if ($('.submit-payment').css('display') === 'none') {
                updateCheckoutStep(4);
            } else {
                updateCheckoutStep(5);
            }
        });

        $(document).on('click', '.place-order', function () {
            updateCheckoutStep(5);
        });
    },
    compare: function () {},
    product: function () {
        $('.btn.add-to-cart').on('click', function () {
            addToCart($.parseJSON($(this).attr('data-gtmdata')), $('.quantity-select').val(), $(this).attr('data-currency'));
        });
    },
    search: function () {},
    storefront: function () {},
    wishlist: function () {},
    // events that should happen on every page
    all: function () {
        $('.pdp-link .link, .image-container a').on('click', function () {
            productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.thumb-link').on('click', function () {
            productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.has-sub-menu').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.primary-logo').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
        $(document).on('click', '.remove-line-item button.remove-product', function () {
            var productObj = $.parseJSON($(this).attr('data-gtmdata'));
            var currency = $(this).attr('data-currency');
            $(document).on('click', '.cart-delete-confirmation-btn', function () {
                removeFromCart(productObj, getQuantityValue(productObj), currency);
            });
        });
    }
};

/**
 * Updates the current step in the checkout flow
 * @param {Integer} step the step number the flow is currently on
 */
function updateCheckoutStep(step) {
    var obj = {
        event: 'checkout',
        ecommerce: {
            checkout: {
                actionField: {
                    step: step
                }
            }
        }
    };
    dataLayer.push(obj);
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a product click
 * @TODO revisit the element event_info.label when Quickview is implemented
 */
function productClick (productObject) {
    var obj = {
        event: 'productClick',
        event_info: {
            label: 'PDP'
        },
        ecommerce: {
            click: {
                actionField: {list: 'SearchResults'},
                products: []
            }
        }
    };
    obj.ecommerce.click.products.push(productObject);
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Object} productObject The product data
 * @param {String} quantity
 * @param {String} currency
 */
function addToCart (productObject, quantity, currency) {
    var quantityObj = {'quantity': quantity},
        obj = {
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: []
                },
                currencyCode: currency
            }
        };
    obj.ecommerce.add.products.push($.extend(productObject,quantityObj));
    dataLayer.push(obj);
}

/**
 * @description Convenience method for getting quantity went removing
 * @param {Object} productObj productObj
 * @return {int} quantity value of quantity
 */
function getQuantityValue(productObj) {
    var quantity = 1;
    $('select.quantity').each(function () {
        var datapid = $(this).attr('data-pid');
        if (datapid === productObj.id || datapid === productObj.childID) {
            quantity = $(this).val();
        }
    });
    return quantity;
}

/**
 *
 * @param {Object} productObject
 * @param {String|Number} quantity
 * @param {string} currency
 */
function removeFromCart (productObject, quantity, currency) {
    var quantityObj = {'quantity': quantity},
        obj = {
            event: 'removeFromCart',
            ecommerce: {
                remove: {
                    products: []
                },
                currencyCode: currency
            }
        };
    obj.ecommerce.remove.products.push($.extend(productObject,quantityObj));
    dataLayer.push(obj);
}

/**
 * @description Convenience method for creating a click event.
 * @param {String} event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventLabel
 */
function pushEvent (event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

/**
*
* @description Initialize the tag manager functionality
* @param {string} nameSpace The current name space
*/
exports.init = function (nameSpace) {
   var ns = nameSpace || pageContext.ns;
   if (ns && events[ns]) {
       events[ns]();
   }
   events.all();
};
