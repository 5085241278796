/**
 * Arlo SFCC Static
 * Main entry point.
 */

/**
  * Global Modules
  */
import Modal from './Modal';
const { adjustWarrantyPriceSpace } = require('../checkout/adjustWarrantyPriceSpace');

new Modal('region-selector-content');// eslint-disable-line no-new

 /**
  * Handle Mobile Navigation
  */
import MobileNavigation from './MobileNavigation';
const mobile_nav = new MobileNavigation();// eslint-disable-line camelcase

/**
 * Main Variables
 */

var body = document.querySelector('body');

/**
 * HEADER FUNCTIONALITY
 */
var header = document.querySelector('.header');
var miniCartHeader = document.querySelector('.minicart-wrap__header');
var headerBanner = document.querySelector('.header__banner');
var headerContainer = document.querySelector('.header__container');

if (headerBanner) {
    if (headerBanner.classList.contains('active')) {
        window.addEventListener('scroll', () => {
            if (window.scrollY > headerBanner.offsetHeight) {
                if (headerContainer && headerContainer.classList) {
                    headerContainer.classList.add('fixed');
                }
                if (miniCartHeader && miniCartHeader.classList) {
                    miniCartHeader.classList.add('scrolled');
                }
            } else {
                if (headerContainer && headerContainer.classList) {
                    headerContainer.classList.remove('fixed');
                }
                if (miniCartHeader && miniCartHeader.classList) {
                    miniCartHeader.classList.remove('scrolled');
                }
            }
        });
    } else {
        headerContainer.classList.add('fixed');
    }
}

var hamburger = document.querySelector('.header__mobile-hamburger');

if (hamburger) {
    hamburger.addEventListener('click', (e) => {
        e.preventDefault();
        if (hamburger.classList.contains('active')) {
            hamburger.classList.remove('active');
            body.classList.remove('no-scroll');
            headerContainer.classList.remove('menu-open');
        } else {
            hamburger.classList.add('active');
            body.classList.add('no-scroll');
            headerContainer.classList.add('menu-open');
        }
        setTimeout(() => { mobile_nav.resetMobileNav(); }, 500);
    }, false);

    window.addEventListener('resize', () => {
        if (window.innerWidth >= 1200) {
            hamburger.classList.remove('active');
            body.classList.remove('no-scroll');
            headerContainer.classList.remove('menu-open');
        }
    });
}

/**
* CHECKOUT HEADER FUNCTIONALITY
*/
var expandOrderSummaryButton = document.querySelector('[data-expand-summary]');

if (expandOrderSummaryButton) {
    expandOrderSummaryButton.addEventListener('click', () => {
        jQuery('[data-order-summary-column]').slideToggle('slow');
        jQuery('[data-expand-arrow]').toggleClass('expanded');
        adjustWarrantyPriceSpace();
    });
}

/**
 * FOOTER FUNCTIONALITY
 */
var newsletterInput = document.querySelector('.footer__newsletter-form input');
var newsletterButton = document.querySelector('.footer__newsletter-form button');
var newsletterDesktopPlaceholder = document.querySelector('.footer__newsletter-placeholder');

if (newsletterInput) {
    newsletterInput.addEventListener('input', () => {
        if (newsletterInput.value) {
            newsletterButton.disabled = false;
            newsletterDesktopPlaceholder.style.display = 'none';
        } else {
            newsletterButton.disabled = true;
            newsletterDesktopPlaceholder.style.display = '';
        }
    });
}

/**
 * MODAL/POPUP FUNCTIONALITY
 */
var modalOverlay = document.querySelector('.modal-overlay');
var searchIcon = document.querySelector('.item-search');
var searchBar = document.querySelector('.search-bar');
var cartIcon = document.querySelector('.item-cart');
var miniCart = document.querySelector('.minicart_wrap');
var searchBarClose = document.querySelector('.search-bar__close');
var miniCartClose = document.querySelector('.minicart__close');

/**
 * Removes classes to remove modal overlays.
 */
function closeOverlays() {
    body.classList.remove('no-scroll');
    modalOverlay.classList.remove('active');
    searchBar.classList.remove('active');
    miniCart.classList.remove('active');
    header.classList.remove('cart-open');
    headerBanner.classList.remove('cart-open');
}

/**
 * Search Icon click
 */
if (searchIcon) {
    searchIcon.addEventListener('click', (e) => {
        e.preventDefault();
        body.classList.add('no-scroll');
        $('input.search-field').val('');
        $('input.search-field').html('');
        $('input.search-field').focus();
        modalOverlay.classList.add('active');
        searchBar.classList.add('active');
        $('.search-bar__results').html('');
    });
}

/**
 * Search Bar Close Click
 */
if (searchBarClose) {
    searchBarClose.addEventListener('click', () => {
        closeOverlays();
    });
}

/**
 * Cart Icon click
 */
if (cartIcon) {
    cartIcon.addEventListener('click', (e) => {
        if ($('body').width() < 744) {
            e.preventDefault();
            if ($('.page').attr('data-action') !== 'Cart-Show') {
                body.classList.add('no-scroll');
                modalOverlay.classList.add('active');
                miniCart.classList.add('active');
                header.classList.add('cart-open');
                headerBanner.classList.add('cart-open');
            }
        }
    });
}

/**
 * Mini Cart Close Click
 */
if (miniCartClose) {
    miniCartClose.addEventListener('click', () => {
        closeOverlays();
    });
}

/**
 * Modal Overlay Click
 */
if (miniCartClose) {
    modalOverlay.addEventListener('click', () => {
        closeOverlays();
    });
}

/**
 * Floating Labels for input fields
 */
var floatContainers = document.querySelectorAll('.form-float-container');

// add active class
var handleFocus = (e) => {
    var target = e.target;
    target.parentNode.classList.add('active');
    target.setAttribute('placeholder', (target.getAttribute('data-placeholder') || ''));
};

// remove active class
var handleBlur = (e) => {
    var target = e.target;

    if (!target.value) {
        target.parentNode.classList.remove('active');
    }

    target.removeAttribute('placeholder');
};

var bindEvents = (element) => {
    var floatField = element.querySelector('input') ? element.querySelector('input') : element.querySelector('textarea');
    floatField.addEventListener('focus', handleFocus);
    floatField.addEventListener('blur', handleBlur);
};

if (floatContainers.length > 0) {
    floatContainers.forEach((element) => {
        if (element.querySelector('select')) return;

        var inputElement = element.querySelector('input') ? element.querySelector('input') : element.querySelector('textarea');

        if (inputElement.value) {
            element.classList.add('active');
        }

        bindEvents(element);
    });
}

 /**
  * Handle Navigation Dropdown
  */
$('.toggle-nav-dropdown').on('mouseenter', function () {
    $('.navigation-dropdown').removeClass('show');
    var catId = $(this).data('cat-id');
    $(`.navigation-dropdown[data-cat-id="${catId}"]`).addClass('show');
    document.querySelector('.modal-overlay').classList.add('active');
});
$('.header__nav-link:not(.toggle-nav-dropdown)').on('mouseenter', function () {
    $('.navigation-dropdown').removeClass('show');
    document.querySelector('.modal-overlay').classList.remove('active');
});
$('.navigation-dropdown').on('mouseleave', function () {
    $('.navigation-dropdown').removeClass('show');
    document.querySelector('.modal-overlay').classList.remove('active');
});

$('.header__container').on('mouseleave', function () {
    $('.navigation-dropdown').removeClass('show');
    document.querySelector('.modal-overlay').classList.remove('active');
});

$('body').on('click', '.flyout-cart__continue', function () {
    $('.modal__close').trigger('click');
});

$('body').on('click', '.mobile-navigation__product-card a', function (e) {
    e.preventDefault();
    window.location.href = $(this).attr('href');
});
