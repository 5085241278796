require('@babel/polyfill');
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var tagManager = require('int_googletags/tagManager');
import FooterAnimate from './components/FooterAnimate';
const { adjustWarrantyPriceSpace } = require('./checkout/adjustWarrantyPriceSpace');
import AccordionModule from './components/accordionModule';
import CrossBrowserTransparentVideo from './components/CrossBrowserTransparentVideo';

$(document).ready(function () {
    tagManager.init(window.pageContext.ns);
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('lyonscg/components/footer'));
    processInclude(require('lyonscg/components/backtotop'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    // processInclude(require('base/components/search'));
    processInclude(require('lyonscg/components/clientSideValidation'));
    processInclude(require('./components/carousels'));
    processInclude(require('lyonscg/components/tooltips'));
    processInclude(require('./components/search'));
    processInclude(require('./components/envoymain'));
    processInclude(require('./components/footer'));
    adjustWarrantyPriceSpace();
    new AccordionModule(); // eslint-disable-line no-new
    new CrossBrowserTransparentVideo(); // eslint-disable-line no-new

    if ($('.mobile-navigation__product-card.bundle').length) {
        $('.mobile-navigation__product-card.bundle').parents('ul.mobile-navigation__list--third-level').addClass('bundle-img-heigth');
    }

    $('.js-warnty-details').click(function () {
        var $warrantyBlock = $(this).closest('.view-warrnty').next('.warranty-details-block');
        if ($warrantyBlock.length) {
            $(this).toggleClass('active');
            if ($warrantyBlock.is(':visible')) {
                $warrantyBlock.slideUp();
            } else {
                $warrantyBlock.removeClass('d-none').slideDown();
            }
        }
    });

    $('select#regionSelect').prop('selectedIndex', 0);

    $('select#regionSelect').on('change', function (e) {
        e.preventDefault();
        window.location.href = $(this).find('option:selected').data('url');
    });

    // Use select2 for selects

    $('body .custom-select-dropdown').select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'below',
        width: 'resolve',
        height: '40px'
    });

    $('body .custom-select-dropdown-compare').select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'below',
        width: '100%'
    });

    $('body .custom-select-dropdown-bluegray').select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'below',
        selectionCssClass: 'bluegray',
        dropdownCssClass: 'bluegray',
        width: '100%'
    });

    $('body .custom-select-dropdown-lighttint').select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'below',
        selectionCssClass: 'lighttint',
        dropdownCssClass: 'lighttint',
        width: '100%'
    });

    if (!window.localStorage.cookie_accept) {
        $('.cookie-warning-messaging').show();
    }

    $('body').on('click', '.ats-FAQ-tabs .tab', function (e) {
        e.preventDefault();
        $('.ats-FAQ-tabs .tab').removeClass('active');
        $(this).addClass('active');
        $('.faq-ats').addClass('d-none');
        var slectedTab = $(this).attr('data-tab');
        $('.' + slectedTab).removeClass('d-none');
    });

    function resizeContent() { // eslint-disable-line
        if ($('.header__logo').length) {
            var logo = $('.header__logo').offset().left;

            if ($('#protect-eveything').length && $(window).width() > 1200) {
                $('.campaign-hero__content, .feature-overview-slider').css('padding-left', logo);
            }

            if ($('#industry-banner, .hub-banner-copy').length && $(window).width() > 1200) {
                $('.ind-banner-copy, .hub-banner-copy').css('left', logo + 74);
                $('.ind-banner-overlap').css('right', logo);
            }

            if ($('.industry-safe-secure').length && $(window).width() > 1200) {
                $('.industry-safe-secure').css('padding-left', logo - 8);
            }

            if ($('.wrnty-banner-text').length && $(window).width() > 1200) {
                $('.wrnty-banner-text').css('padding-left', logo + 10);
            }

            if ($('.bussiness-security-breadcrumb').length && $(window).width() > 1200) {
                $('.bussiness-security-breadcrumb').css('padding-left', logo + 10);
            }
        }
    }

    resizeContent();

    $(window).resize(function () {
        resizeContent();
    });

    $('body').on('click', '.cookie-warning-messaging button.close', function (e) {
        e.preventDefault();
        window.localStorage.setItem('cookie_accept', true);
        $('.cookie-warning-messaging').hide();
    });

    $('body').on('click', '.cookie-link', function (e) {
        e.preventDefault();
        window.localStorage.setItem('cookie_accept', true);
        $('.cookie-warning-messaging').hide();
        window.location.href = $(this).attr('href');
    });

    $('body').click(function () {
        setTimeout(function () {
            var statecode = $('#shippingStatedefault').val() || $('#state').val();
            var stateval = $('#shippingStatedefault option[value=' + statecode + ']').html() || $('#state option[value=' + statecode + ']').html();
            $('#select2-shippingStatedefault-container, #select2-state-container').attr('title', stateval);
            $('#select2-shippingStatedefault-container, #select2-state-container').html(stateval);
        }, 500);
    });

    new FooterAnimate(); // eslint-disable-line no-new

    const ATSPriceTwoline = document.getElementsByClassName('ATS-price-twoLine');
    if (ATSPriceTwoline.length > 0) {
        for (let i = 0; i < ATSPriceTwoline.length; i++) {
            const ATSPriceEle = ATSPriceTwoline[i];
            const PriceText = ATSPriceEle.innerText;
            const splitText = PriceText.replace('mo.', 'mo.\n');
            ATSPriceEle.innerText = splitText;
        }
    }

    window.addEventListener('load', function () {
        if (typeof (OneTrust) !== 'undefined') { // eslint-disable-line no-undef
            OneTrust.OnConsentChanged(function () { // eslint-disable-line
                if (typeof (OnetrustActiveGroups) !== 'undefined' && OnetrustActiveGroups.length) { // eslint-disable-line no-undef
                    if ($('.js-account-dashboardpage').length > 0) {
                        if ($('.js-account-dashboardpage').val() === 'getConsentDone') {
                            return false;
                        }
                    }
                    var selectedCookiPrefs = OnetrustActiveGroups; // eslint-disable-line no-undef
                    if (selectedCookiPrefs.length > 0) {
                        if (selectedCookiPrefs.charAt(0) === ',') {
                            selectedCookiPrefs = selectedCookiPrefs.substring(1);
                        }
                        if (selectedCookiPrefs.charAt(selectedCookiPrefs.length - 1) === ',') {
                            selectedCookiPrefs = selectedCookiPrefs.substring(0, selectedCookiPrefs.length - 1);
                        }
                        if ($('.js-updatecookieprefs-url').length) {
                            var url = $('.js-updatecookieprefs-url').val();
                            $.ajax({
                                url: url,
                                type: 'post',
                                data: {
                                    selectprefs: selectedCookiPrefs
                                },
                                success: function () {
                                    // Left blank on intention
                                }
                            });
                        }
                    }
                }
            });
        }
    });

    window.onscroll = function () {
        if (window.scrollY > 100) {
            $('.custom-select').select2('close');
        }
    };

    var $headerBanner = $('.js-header-banner');
    if ($headerBanner.length) {
        var quoteIndex = -1;
        // eslint-disable-next-line require-jsdoc, no-inner-declarations
        function showNextQuote() {
            ++quoteIndex;
            $headerBanner.eq(quoteIndex % $headerBanner.length)
                .fadeIn(1500)
                .delay(5000)
                .fadeOut(1500, showNextQuote);
        }
        showNextQuote();
    }
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('svg4everybody');
require('slick-carousel');
require('gsap');
require('./components/select2');
require('./components/select2-dropdownPosition');

