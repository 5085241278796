'use strict';
/**
 * @name getEmailSubscriptionError
 * @description find out the error msg for email
 * subscription based on error code and control
 * @param {string} errorcode input param
 * @param {string} errorcontrol input param
 * @param {string} form input param
 * @returns {string} error message
 */
function getEmailSubscriptionError(errorcode, errorcontrol, form) {
    var strError = '';
    switch (errorcode) {
        case '1': case '6': case '7': case '9':
            strError = 'An error has occurred while attempting to save your subscriber information.';
            break;

        case '2':
            strError = 'The list provided does not exist.';
            break;

        case '3':
            strError = 'Information was not provided for a mandatory field.';
            break;

        case '4':
            strError = 'Invalid information was provided';
            break;

        case '5':
            if (form === 'footer') {
                strError = 'The list provided does not exist.';
                break;
            } else {
                strError = 'The list provided does not exist.';
                break;
            }

        case '8':
            if (form === 'footer') {
                strError = 'You have already subscribed to this list.';
                break;
            } else {
                strError = 'You have already subscribed to this list, only first time subscribers are eligible for this promotion.';
                break;
            }
        case '10':
            strError = 'An error has occurred while attempting to save subscriber information.';
            break;

        case '12':
            strError = 'The email address is already unsubscribed.';
            break;

        case '13' :
            strError = 'Check that the list ID or MID specified in your code is correct.';
            break;

        case '14' :
            strError = 'Subscriber key is not enabled.';
            break;

        default:
            strError = 'Oops! something went wrong. Please try again.';
    }
    if (form === 'modal') {
        window.localStorage.removeItem('arlPromoOverlayModal');
    }
    return strError;
}

module.exports = {
    getEmailSubscriptionError: getEmailSubscriptionError
};
