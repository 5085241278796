'use strict';

/**
 * @namespace productSetVariation
 * @memberof org_arlo
 * Manages the change of a product variant by color on PDP for a product set
 */

var updateDom = require('../search/search').updateDom;

module.exports = {
    methods: {
        /**
         * Updates the appearance of the color swatches when selected
         *
         * @param {jQuery} $element - The selected swatch element
         * @returns {void}
         */
        updateColorSwatch: function ($element) {
            var productId = $element.find('span').data('color-swatch-product-id');
            var $allSwatches = $('span[data-color-swatch-product-id]');

            $allSwatches.each(function () {
                var $swatch = $(this);

                if ($swatch.data('color-swatch-product-id') === productId) {
                    $swatch.addClass('selected');
                } else {
                    $swatch.removeClass('selected');
                }
            });
        },
        /**
         * Rerenders a product set on PDP if a color variant is selected
         *
         * @param {string} pid The id of a product.
         * @param {string} url The url to call to receive the rendering template
         * @returns {void}
         */
        renderVariantsOnPdp: function (pid, url) {
            var $productDetails = $('.js-no-gutters');

            $productDetails.spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                data: {
                    pid: pid,
                    rerenderSetVariant: true
                },
                success: function (data) {
                    var sliceText = require('./detail').sliceText;
                    var slickConfigs = require('../config/slickConfigs');
                    var $results = $(data);

                    [
                        '.js-product-id',
                        '.product-overview__gallery-slides',
                        '.js-affirm-message-container',
                        '.js-individual-products__wrapper',
                        '.js-product-overview__description',
                        '.js-product-overview__prices',
                        '.js-product-set-total-wrap',
                        '.js-cart-and-ipay',
                        '.js-promo-message',
                        '.js-product-recommendations',
                        '.js-product-faq',
                        '.js-disclaimers'
                    ].forEach(function (selector) {
                        updateDom($results, selector);
                    });

                    sliceText();
                    $('.product-tile-caro').not('.slick-initialized').slick(slickConfigs.productTiles);
                    $('.js-product-nav').find('.js-save-amount-text').addClass('d-none');

                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        },
        /**
         * Updates the information for of the add-to-cart button
         *
         * @param {jQuery} $element - The selected swatch element
         * @returns {void}
         */
        updateBasketInformation: function ($element) {
            var productId = $element.parent().data('obj').id;
            $('.js-btn__add-to-cart-global_bundle-pdp').attr('data-pid', productId);
        },
        /**
         * Updates the displayed product names
         *
         * @param {Object} data - the object containing names for different parts of the page
         * @returns {void}
         */
        updateProductNames: function (data) {
            $('.js-product-main-name').text(data.mainName);
            $('.js-product-sticky-bar-name').text(data.stickyBarName);
        }
    }
};
