/**
 * Adjusts the margin-top of warranty total price elements within product items.
 * This function calculates the margin-top based on the heights of specific elements
 * within each product item and applies the calculated margin-top to the
 * '.js-warrantytotalprice' element.
 */
function adjustWarrantyPriceSpace() {
    if ($('#checkout-main').length && $('.order-product-summary').length) {
        const productItems = document.querySelectorAll('.product-summary-block .product-line-item');

        productItems.forEach((item) => {
            try {
                const productAttributeHeight = $(item).find('.wrnty-attr-height').height() || 0;
                const productNameHeight = $(item).find('.wrnty-prod-height').height() || 0;
                const productItemPriceHeight = $(item).find('.line-item-unit-price').height() || 0;
                const productQuantityHeight = $(item).find('.line-item-pricing-info').height() || 0;

                const totalAttributeNameHeight = productAttributeHeight + productNameHeight;
                const totalPriceQuantityHeight = productItemPriceHeight + productQuantityHeight;
                const finalMarginTop = totalAttributeNameHeight - totalPriceQuantityHeight;

                $(item).find('.js-warrantytotalprice').css('margin-top', finalMarginTop);
            } catch (error) {
                console.error('Error processing product item:', item, error);
            }
        });
    }
}

export { adjustWarrantyPriceSpace };
