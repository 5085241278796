'use strict';

var base = require('../product/base');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    if ($('.checkout__steps-wrap').length) {
        if ($('.card-header-custom .small-text').length) {
            $('.card-header-custom .small-text').text(data.resources.numberOfItems);
        }
    }
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    if ($('.grand-total').length && $('.grand-total').hasClass('js-checkoutsummarytotal')) {
        $('.grand-total span.grand-total-sum').empty().append(data.totals.grandTotal);
    } else {
        $('.grand-total').empty().append(data.totals.grandTotal);
    }
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        if (item.isBonusProductLineItem === false) {
            if (item.priceTotal.nonAdjustedBasePrice) {
                $('.item-nonadjusted-' + item.UUID).empty().append(item.priceTotal.nonAdjustedBasePrice);
                $('.strike-through.non-adjusted-price.' + item.UUID).removeClass('non-adjusted-price');
            } else {
                $('.item-nonadjusted-' + item.UUID).empty();
                $('.strike-through.non-adjusted-price.' + item.UUID).addClass('non-adjusted-price');
            }
            $('.item-unit-' + item.UUID).empty().append(item.priceTotal.basePrice);
            $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
            // Warranty Updates
            if ($('.uuid-' + item.UUID).find('.js-warrantytotalprice').length) {
                if ($('.uuid-' + item.UUID).find('.js-warrantytotalprice').hasClass('isbundleitem')) {
                    $('.uuid-' + item.UUID).find('.js-warrantytotalprice').text($('.js-site-currencysymbol').val() + Number(item.warrantyData.warrantyUnitPrice).toFixed(2));
                } else {
                    $('.uuid-' + item.UUID).find('.js-warrantytotalprice').text($('.js-site-currencysymbol').val() + Number(item.warrantyData.warrantyUnitPrice * item.quantity).toFixed(2));
                }
            }
            if ($('.checkout__steps-wrap').length) {
                if ($('.product-summary-block .product-line-item[data-product-line-item=' + item.UUID + ']').length) {
                    if ($('.product-summary-block .product-line-item[data-product-line-item=' + item.UUID + ']').find('.js-warrantytotalprice').length) {
                        if ($('.product-summary-block .product-line-item[data-product-line-item=' + item.UUID + ']').find('.js-warrantytotalprice').hasClass('isbundleitem')) {
                            $('.product-summary-block .product-line-item[data-product-line-item=' + item.UUID + ']').find('.js-warrantytotalprice').text($('.js-site-currencysymbol').val() + Number(item.warrantyData.warrantyUnitPrice).toFixed(2));
                        } else {
                            $('.product-summary-block .product-line-item[data-product-line-item=' + item.UUID + ']').find('.js-warrantytotalprice').text($('.js-site-currencysymbol').val() + Number(item.warrantyData.warrantyUnitPrice * item.quantity).toFixed(2));
                        }
                    }
                    $('.product-summary-block .product-line-item[data-product-line-item=' + item.UUID + ']').find('.line-item-pricing-info .qty-card-quantity-count').text(item.quantity);
                }
            }
        }
    });

    if (data) {
        var affirmSubtotal = data.totals && data.totals.subTotal.length > 0 ? data.totals.subTotal.replace(/[^\d.]+/g, '') : 0;
        if ($('.js-affirm-cart-total').length > 0) {
            $('.js-affirm-cart-total').attr('data-amount', affirmSubtotal * 100);
            // Display the affirm section if total more than threshold
            if (Number(affirmSubtotal) >= Number($('.js-affirm-cart-mintotal').val())) {
                if (data.hasWarrantyItems) {
                    $('.js-affirm-cart-total').addClass('d-none');
                } else {
                    $('.js-affirm-cart-total').removeClass('d-none');
                }
            } else {
                $('.js-affirm-cart-total').addClass('d-none');
            }
        }
        if ($('.js-affirm-minicart-total').length > 0) {
            $('.js-affirm-minicart-total').attr('data-amount', affirmSubtotal * 100);
            // Display the affirm section if total more than threshold
            if (Number(affirmSubtotal) >= Number($('.js-affirm-minicart-mintotal').val())) {
                if (data.hasWarrantyItems) {
                    $('.js-affirm-minicart-total').addClass('d-none');
                } else {
                    $('.js-affirm-minicart-total').removeClass('d-none');
                }
            } else {
                $('.js-affirm-minicart-total').addClass('d-none');
            }
        }
        if ($('.js-affirm-checkout-mintotal').length > 0) {
            // reload the page if qty updated in checkout
            // reload needed as affirm totals needs to be updated and basket object
            location.reload();
        }
        if (typeof affirm !== 'undefined') { // eslint-disable-line
            affirm.ui.ready(function() { // eslint-disable-line
                affirm.ui.refresh(); // eslint-disable-line
            });
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    if ($('.alert-danger').length < 1) {
        var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
         'fade show" role="alert">' +
         '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
         '<span aria-hidden="true">&times;</span>' +
         '</button>' + message + '</div>';

        $('.cart-error').append(errorHtml);
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem) {
        $('.availability-' + lineItem.UUID).empty();
    }

    if (lineItem && lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

       /* if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }*/
    }

    if (lineItem) {
        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var cameraAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.id === 'numbercameras';
        });

        if (cameraAttr) {
            var cameraSelector = '.numbercameras-' + uuid;
            var newNumber = 'Number of Cameras: ' + cameraAttr.displayValue;
            $(cameraSelector).text(newNumber);
        }

        var lightAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.id === 'Number of Lights';
        });

        if (lightAttr) {
            var lightSelector = '.Lights-' + uuid;
            var newNumberLights = 'Number of Lights: ' + lightAttr.displayValue;
            $(lightSelector).text(newNumberLights);
        }

        var qCamAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.id === 'Q.cameras';
        });

        if (qCamAttr) {
            var qCamSelector = 'p[class$="Q.cameras-' + uuid + '"]';
            var newNumberCams = 'Select Camera: ' + qCamAttr.displayValue;
            $(qCamSelector).text(newNumberCams);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.small[0].url);
        $(imageSelector).attr('alt', lineItem.images.small[0].alt);
        $(imageSelector).attr('title', lineItem.images.small[0].title);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }

    $('.uuid-' + uuid + ' .line-item-name').html('<a href="' + lineItem.productUrl + '">' + lineItem.productName + '</a>');
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" role="dialog">'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        &times;'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    // var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        base.methods.editBonusProducts(response.newBonusDiscountLineItem);
    } else {
        /* if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
            '<div class="add-to-cart-messages"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
        */
        $('#flyout-cart').show();
    }
}

/**
 * Function to render a fresh Minicart HTML
 */
function openNewMinicart() {
    var modalOverlay = document.querySelector('.modal-overlay');
    var miniCart = document.querySelector('.minicart_wrap');
    var body = document.querySelector('body');
    var url = $('.cart-link').attr('data-href');
    body.classList.add('no-scroll');
    $.get(url, function (data) {
        $('.minicart_wrap').empty();
        $('.minicart_wrap').parent().each(function () {
            if (!$(this).hasClass('mobile-header')) {
                $(this).children('.minicart_wrap').append(data);
            }
        });
        if (typeof affirm !== 'undefined') { // eslint-disable-line
            affirm.ui.ready(function() { // eslint-disable-line
                affirm.ui.refresh(); // eslint-disable-line
            });
        }
        $('.mob-byb-discount').css('width', $('.hybridbundle-line-item').width());
        modalOverlay.classList.add('active');
        miniCart.classList.add('active');
        body.classList.add('mini-cart-active');
        $.spinner().stop();
    });
}

/**
 * Generates the modal window on the first call.
 *
 */
function getPaymentModalHtmlElement() {
    if ($('#spinnerModal').length !== 0) {
        $('#spinnerModal').remove();
    }
    // Only for mobile devices
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade d-lg-none" id="spinnerModal" role="dialog" data-keyboard="false" data-backdrop="static">'
        + '<div class="modal-dialog d-lg-none modal-lg modal-dialog-centered">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<div class="spinner-header">'
        + '<div class="loder-img"></div>'
        + '</div>'
        + '</div>'
        + '<div class="modal-body">'
        + '<div class="spinner-body">'
        + '</div>'
        + '</div>'
        + '<div class="modal-footer">'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

module.exports = function () {
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    // Arlo 887:- Event to remove trailing and leading blank space
    $('body').on('focusout', '#couponCode', function () {
        var inputCouponCode = $(this).val();
        $(this).val(inputCouponCode.trim());
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                var $removedProduct;
                $('.minicart_wrap__items').spinner().start();
                if (data.basket.items.length === 0 && $('#checkout-main').length < 1) {
                    for (var s = 0, t = $('.product-line-item').length; s < t; s++) {
                        if ($('.product-line-item')[s].getAttribute('data-product-line-item') === uuid) {
                            $removedProduct = $('.product-line-item')[s];
                            s = t;
                        }
                    }
                    $removedProduct.remove();
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var k = 0; k < data.toBeDeletedUUIDs.length; k++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[k]).remove();
                        }
                    }
                    if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                        _etmc.trackCart([{ "clear_cart":true}]); // eslint-disable-line
                    }
                    $('.minicart_wrap').removeClass('active');
                    location.reload();
                } else if ($('#checkout-main').length) {
                    // reload the page in checkout to display correct line items and totals
                    location.reload();
                } else {
                    $('.product-line-item').spinner().start();
                    var $numOfItems = $('.minicart_wrap__count');
                    var $subTotal = $('.minicart_wrap__footer-totals-total');
                    var $cartItems = $('.cart-count');
                    for (var x = 0, y = $('.product-line-item').length; x < y; x++) {
                        if ($('.product-line-item')[x].getAttribute('data-product-line-item') === uuid) {
                            $removedProduct = $('.product-line-item')[x];
                            x = y;
                        }
                    }
                    $numOfItems.html(data.basket.resources.numberOfItems);
                    $subTotal.html(data.basket.totals.subTotal);
                    $cartItems.html(data.basket.numItems);
                    $removedProduct.remove();
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                        var cart = {};
                        cart = data.basket.trackCartDatalayer;
                        _etmc.trackCart([{cart}]); // eslint-disable-line
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    if (!data.basket.paypalApplicable) {
                        $('.paypal_button_div').addClass('d-none');
                    } else {
                        $('.paypal_button_div').removeClass('d-none');
                    }
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    var changeQtyCheck = false;
    $('body').on('change', '.quantity-form > .quantity', function (e) {
        e.stopImmediatePropagation();
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var minusBtn = $(this).siblings('.liq-minus');
        var minusBtnDisabled = minusBtn ? $(minusBtn).prop('disabled') : false;
        var headerCartCount = $('.cart-count');
        var numOfItems = $('.minicart_wrap__count');

        if (quantity === '1' && minusBtnDisabled === false) {
            $(minusBtn).attr('disabled', true).text('');
        } else if (quantity !== '1' && minusBtnDisabled) {
            $(minusBtn).removeAttr('disabled').text('-');
        }

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        if ($(this).hasClass('reduceqty')) {
            $(this).removeClass('reduceqty');
            urlParams.isqtyreduce = true;
        } else {
            urlParams.isqtyreduce = false;
        }

        // warranty updates
        if ($('.uuid-' + uuid).find('.js-itemwarranty-checkbox:checked').length) {
            urlParams.updatewarranty = true;
            var warrantyObjstr = $('.uuid-' + uuid).find('.js-itemwarranty-checkbox:checked').attr('data-warrantyobj');
            var warrantyObj = null;
            if (warrantyObjstr) {
                try {
                    warrantyObj = JSON.parse(warrantyObjstr);
                } catch (e1) {
                    console.error('warranty data corrupt');
                }
                urlParams.wpid = warrantyObj.warrantyPID;
            }
        }
        url = appendToUrl(url, urlParams);

        if ($('.cart.cart-page').length) {
            $('body').spinner().start();
        } else {
            $('.minicart_wrap').spinner().start();
        }

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                // warranty updates
                if ($('.uuid-' + uuid).find('.js-itemwarranty-checkbox:checked').length) {
                    var colorInterval = setInterval(function () {
                        $('.uuid-' + uuid).find('.js-warrantytotalprice').toggleClass('warranty-bling');
                    }, 500);
                    setTimeout(function () {
                        clearInterval(colorInterval);
                        $('.uuid-' + uuid).find('.js-warrantytotalprice').removeClass('warranty-bling');
                    }, 3000);
                }
                if (!data.paypalApplicable) {
                    $('.paypal_button_div').addClass('d-none');
                } else {
                    $('.paypal_button_div').removeClass('d-none');
                }
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);
                if ($('#checkout-main').length || ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length)) {
                    // location.reload();
                    if (window.location.pathname && !window.location.pathname.toLowerCase().includes('cart')) {
                        changeQtyCheck = true;
                    }
                }
                $.spinner().stop();
                if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                    var cart = {};
                    cart = data.trackCartDatalayer;
                    _etmc.trackCart([{cart}]); // eslint-disable-line
                }
                if (headerCartCount && data && data.numItems) {
                    $(headerCartCount).text(data.numItems);
                }
                if (numOfItems && data && data.resources && data.resources.numberOfItems) {
                    $(numOfItems).html(data.resources.numberOfItems);
                }
                // Add new bonus products if any
                var cartItems = data.items;
                var latestItemsInCart = [];
                cartItems.forEach(function (item) {
                    latestItemsInCart.push(item.UUID);
                    if ($('.uuid-' + item.UUID).length < 1) {
                        if (item.isBonusProductLineItem && item.isBonusProductLineItem === true) {
                            $('.cart-products').append(`<div class="product-line-item product-info  uuid-${item.UUID}" data-product-line-item="${item.UUID}">
                                                    <div class="item-image">
                                                    <img class="product-image" src="${item.images.small[0].url}" alt="${item.images.small[0].alt}" title="${item.images.small[0].title}">
                                                    </div>
                                                    <div class="item-details">
                                                    <div>Bonus Product</div>
                                                    <div class="line-item-name bolded">
                                                        <a href="${item.productUrl}"> ${item.productName} </a>
                                                    </div>
                                                    <div class="item-attributes small-text">
                                                        <div class="line-item-attributes"> Quantity: ${item.quantity} </div>
                                                        <div class="line-item-availability availability-${item.UUID}"></div>
                                                    </div>
                                                    <div class="line-item-promo item-${item.UUID}">
                                                        <div></div>
                                                    </div>
                                                    </div>
                                                    <div class="item-pricing">
                                                    <div class="line-item-unit-price">
                                                        <div class="price " itemprop="offers" itemscope="" itemtype="http://schema.org/Offer"></div>
                                                    </div>
                                                    <div class="line-item-total-price">
                                                        <div class="price  item-total-${item.UUID}" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer"></div>
                                                    </div>
                                                    </div>
                                                </div>`);
                        }
                    }
                });
                // Remove bonus items if any that are not part of updated basket items
                var itemsToDelete = [];
                $('.product-line-item.product-info').each(function () {
                    if (latestItemsInCart.indexOf($(this).data('product-line-item')) < 0) {
                        itemsToDelete.push($(this).data('product-line-item'));
                    }
                });
                itemsToDelete.forEach(function (itemToDeleteuuid) {
                    if (itemToDeleteuuid !== 'hybridbundle') {
                        $('.uuid-' + itemToDeleteuuid).remove();
                    }
                });
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.minicart_wrap__close', function () {
        if (changeQtyCheck === true) {
            location.reload();
        }
    });

    $('body').on('click', function (e) {
        if (!$(e.target).closest('.minicart_wrap').length) {
            if (changeQtyCheck === true) {
                location.reload();
            }
        }
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    if (!data.paypalApplicable) {
                        $('.paypal_button_div').addClass('d-none');
                    } else {
                        $('.paypal_button_div').removeClass('d-none');
                    }
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else if (data.hasBonusProduct) {
                    location.reload();
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    if (!data.paypalApplicable) {
                        $('.paypal_button_div').addClass('d-none');
                    }
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.coupon-uuid-' + uuid).remove();
                $.spinner().stop();
                // reload the page for bonus products
                location.reload();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        getModalHtmlElement();
        fillModalElement(editProductUrl);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }

        base.updateMainImages(response.data.product);
        base.carouselInit();

        // Update product name
        response.container.find('.product-name').html(response.data.product.productName);
    });

    $('body').on('product:beforeAttributeSelect', function () {
        // Unslick the existing images to prepare them for direct js manipulation
        base.carouselUnslick();
        base.carouselUnslickBonus();
    });


    $('body').on('shown.bs.modal', '#editProductModal, #quickViewModal, #chooseBonusProductModal', function () {
        base.carouselInit();
        base.carouselInitBonus();
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity
        };

        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').remove();
                    $('.modal-backdrop').remove();
                    $('body').removeClass('modal-open');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });
    $(document).on('click', 'button.add-to-cart-recom', function (e) {
        e.preventDefault();
        var addToCartUrl = $(this).parent().find('.add-to-cart-url').val();
        $('body').trigger('product:beforeAddToCart', this);
        var form = {
            pid: $(this).attr('data-pid'),
            quantity: 1,
            options: []
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                        var cart = {};
                        cart = data.cart.trackCartDatalayer;
                            _etmc.trackCart([{cart}]); // eslint-disable-line
                    }
                    handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();

                    var action = $('.page').attr('data-action');
                    var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
                    if (action === 'Cart-Show') {
                        if (maxPids === undefined) {
                            window.location.reload();
                        }
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $(document).on('click', 'button.add-to-cart-upsell', function (e) {
        e.preventDefault();
        var addToCartUrl = $(this).parent().find('.add-to-cart-url').val();
        $('body').trigger('product:beforeAddToCart', this);
        var $this = $(this);
        var isUpSellProduct = $(this).hasClass('add-to-cart-upsell');
        var form = {
            pid: $(this).attr('data-pid'),
            quantity: 1,
            options: [],
            isUpSellProduct: isUpSellProduct
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                        var cart = {};
                        cart = data.cart.trackCartDatalayer;
                            _etmc.trackCart([{cart}]); // eslint-disable-line
                    }
                    $('.flyout-cart__price .qty-flyout-total').text(data.cart.numItems);
                    $('.flyout-cart__price .subtotal-flyout').text(data.cart.totals.subTotal);
                    $this.text(data.message);
                    $this.addClass('upsell-added');
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $(document).on('click', '.js-recs-add-to-cart', function (e) {
        e.preventDefault();
        var addToCartUrl = $(this).attr('data-add-to-cart-url');
        var form = {
            pid: $(this).attr('data-product-id'),
            quantity: 1,
            options: []
        };

        if (addToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (!data.error) {
                        window.location.reload();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $('body').on('change', '.cart-products .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);
        $(this).parents('.product-info').spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                // warranty updates
                if ($('.uuid-' + uuid).find('.js-itemwarranty-checkbox:checked').length) {
                    var colorInterval = setInterval(function () {
                        $('.uuid-' + uuid).find('.js-warrantytotalprice').toggleClass('warranty-bling');
                    }, 500);
                    setTimeout(function () {
                        clearInterval(colorInterval);
                        $('.uuid-' + uuid).find('.js-warrantytotalprice').removeClass('warranty-bling');
                    }, 3000);
                }
                if (!data.paypalApplicable) {
                    $('.paypal_button_div').addClass('d-none');
                } else {
                    $('.paypal_button_div').removeClass('d-none');
                }
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);
                $.spinner().stop();
                if ($('#checkout-main').length || ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length)) {
                    location.reload();
                }
                if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                    var cart = {};
                    cart = data.trackCartDatalayer;
                    _etmc.trackCart([{cart}]); // eslint-disable-line
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('change', '.minicart_wrap .quantitytest', function (e) {
        e.preventDefault();
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.product-line-item').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                var $numOfItems = $('.minicart_wrap__count');
                var $subTotal = $('.minicart_wrap__footer-totals-total');
                var $itemSubtotal = $('.item-total-' + uuid + ' span');
                var $cartItems = $('.cart-count');
                var currentDataPLIPrice = '';
                for (var i = 0, j = data.items.length; i < j; i++) {
                    if (data.items[i].UUID === uuid) {
                        currentDataPLIPrice = data.items[i].priceTotal.price;
                    }
                }
                $numOfItems.html(data.resources.numberOfItems);
                $subTotal.html(data.totals.subTotal);
                $itemSubtotal.attr('content', currentDataPLIPrice);
                $itemSubtotal.html(currentDataPLIPrice);
                $cartItems.html(data.numItems);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

/**
 * Click event for recommendations new smart button ( + )
 * @param {e} event - event of click
 * @param {addToCartUrl} string  - string of add to cart path
 * @param {productID} string - string of ID of product to be added to cart
 * @param {quantity} string - string of qty added to cart (for these recommendations it will always be one
 * @param {isPDPLink} string - string of url to pdp. if this is a variation/master this is a link to the PDP to choose a variation
 * @param {urlParams} string - serialization of parameters to pass to add to cart url
 */
    $('body').on('click', '.cart-upsells-item .recs-add-to-cart', function (e) {
        e.preventDefault();
        var addToCartUrl = $(this).data('add-to-cart-url');
        var productID = $(this).data('product-id');
        var quantity = $(this).data('product-qty');
        var isPDPLink = '';
        var urlParams = {
            pid: productID,
            quantity: quantity
        };
        $(this).parents('.cart-upsells-item').spinner().start();
        if ($(this).data('pdp-link') !== 'undefined' && $(this).data('pdp-link') !== '' && typeof $(this).data('pdp-link') !== 'undefined') {
            isPDPLink = $(this).data('pdp-link');
            window.location.href = isPDPLink;
        } else {
            $.ajax({
                url: addToCartUrl,
                type: 'post',
                data: urlParams,
                dataType: 'json',
                success: function (response) {
                    if (response.error === true) {
                        createErrorNotification(response.message);
                        $('html, body').animate({ scrollTop: $('#checkout-main').offset().top }, 1000);
                    }
                    $.spinner().stop();
                },
                error: function (response) {
                    if (response.error === true) {
                        createErrorNotification(response.message);
                        $('html, body').animate({ scrollTop: $('#checkout-main').offset().top }, 1000);
                    }
                },
                complete: function (response) {
                    if (response.responseJSON.error === false) {
                        window.location.reload();
                    }
                }
            });
        }
    });

    $('body').on('click', '#continueShopping', function (e) {
        e.preventDefault();
        window.location.href = $(this).data('url');
    });

    $('.shippingMethods').select2({
        minimumResultsForSearch: -1
    });

    $('body').on('click', '.js-cart-hybridbundle-increaseqty', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        $(this).prop('disabled', true);
        // Check if the items are available in requested qty
        var canBeAdded = true;
        var hybridBundleQtyToAdd = Number($('.js-cart-hybridbundle-qty').val()) + 1;
        var singleQtyConfig = JSON.parse($('.js-cart-singleqty-hybridconfig').val());
        var itemKeys = Object.keys(singleQtyConfig);
        var message = '';
        for (var i = 0; i < itemKeys.length; i++) {
            var itemKey = itemKeys[i];
            var itemUUID = singleQtyConfig[itemKey].uuid;
            var qtyToAdd = singleQtyConfig[itemKey].qty * hybridBundleQtyToAdd;
            var maxQty = parseInt($('.js-hybrid-bundle-item[data-bundleitemuuid=' + itemUUID + ']').data('maxqty'), 10);
            if (qtyToAdd > maxQty) {
                canBeAdded = false;
                message += '<br>' + $('.js-hybrid-bundle-item[data-bundleitemuuid=' + itemUUID + ']').data('prodname');
            }
        }
        if (canBeAdded) {
            if ($(this).hasClass('is-minicart')) {
                $('.minicart_wrap').spinner().start();
                $.ajax({
                    url: $(this).data('actionurl'),
                    type: 'get'
                }).done(function (data) {
                    if (data.success === true) {
                        $('.minicart_wrap').empty();
                        $('.minicart_wrap').spinner().start();
                        openNewMinicart();
                    }
                });
            } else {
                $('body').spinner().start();
                window.location.href = $(this).data('actionurl');
            }
        } else if (canBeAdded === false) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + $('.js-hybrid-qtyerror-msgs').data('msg1') + ' ' + message + '</div>';
            $('.cart-error').html('');
            $('.cart-error').append(errorHtml);
            $('body').spinner().stop();
            $(this).prop('disabled', false);
            if ($('.js-minicart-block').length) {
                $('.js-minicart-block').animate({
                    scrollTop: 0
                });
            }
        }
    });

    $('body').on('click', '.js-cart-hybridbundle-decreaseqty', function (e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        $(this).prop('disabled', true);
        if ($(this).hasClass('is-minicart')) {
            $('.minicart_wrap').spinner().start();
            $.ajax({
                url: $(this).data('actionurl'),
                type: 'get'
            }).done(function (data) {
                if (data.success === true) {
                    $('.minicart_wrap').empty();
                    $('.minicart_wrap').spinner().start();
                    openNewMinicart();
                }
            });
        } else {
            $('body').spinner().start();
            window.location.href = $(this).data('actionurl');
        }
    });

    // Add on subscription product add to cart
    $('.line-item-quantity input').each(function () {
        var pid = $(this).data('pid');
        if ($('.js-addon-input')) {
            $('.js-addon-input').each(function () {
                if ($(this).data('addonpid') === pid) {
                    $(this).prop('checked', true);
                    $(this).closest('.cart-addon_item').css({ 'pointer-events': 'none' });
                    $(this).closest('.cart-addon_item').prop('disabled', true);
                    $(this).prop('disabled', true);
                }
            });
        }
    });

    $('body').on('click', '.js-cartaddon-radio', function () {
        var addonInput = $(this).closest('.cart-addon_item').find('.js-addon-input');
        var pid = $(addonInput).data('addonpid');
        if (pid && $(addonInput).prop('checked') === true) {
            $(this).prop('disabled', true);
            $('body').spinner().start();
            var addToCartUrl = $('.js-cart-a2curl').val();
            var form = {
                pid: pid,
                childProducts: [],
                quantity: 1
            };
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        if (!data.error) {
                            if ($('.js-cart-reloadurl').val()) {
                                window.location.href = $('.js-cart-reloadurl').val();
                            }
                        }
                        $.spinner().stop();
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }
    });

    $('body').on('click', '.js-applebutton', function () {
        setTimeout(function () {
            // Show Spinner Modal with content after payment popup has opened
            getPaymentModalHtmlElement();
            var spinnerModal = $('#spinnerModal');
            spinnerModal.find('.loder-img').spinner().start();
            spinnerModal.find('.spinner-body').append($('.spinner-content').html());
            spinnerModal.modal('show');
            $('body').spinner().start();
        }, 5000);
    });

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.carouselInit();

    // warranty updates
    $('body').on('change', '.js-itemwarranty-checkbox', function () {
        var warrantyObjstr = $(this).attr('data-warrantyobj');
        var warrantyObj = null;
        var headerCartCount = $('.cart-count');
        var $thisElement = $(this);
        var numOfItems = $('.minicart_wrap__count');
        if (warrantyObjstr) {
            try {
                warrantyObj = JSON.parse(warrantyObjstr);
            } catch (e) {
                console.error('warranty data corrupt');
            }
            var parentqty = $(this).closest('.product-line-item').find('.quantity-form input.quantity').val();
            var stopLoader = true;
            if (this.checked) {
                var addurl;
                // Add warranty to cart or increase qty
                if ($(this).closest('.minicart_wrap__container').length) {
                    addurl = $('.js-addwarranty-minicart').val();
                } else {
                    addurl = $('.js-addwarranty-cart').val();
                }
                if ($('.cart.cart-page').length) {
                    $('body').spinner().start();
                } else {
                    $('.minicart_wrap').spinner().start();
                }
                $.ajax({
                    url: addurl,
                    method: 'POST',
                    data: {
                        wpid: warrantyObj.warrantyPID,
                        quantity: parentqty,
                        parentpid: warrantyObj.parentpid,
                        parentuuid: warrantyObj.parentuuid
                    },
                    success: function (data) {
                        if (!data.error) {
                            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                            updateCartTotals(data);
                            if (!data.paypalApplicable) {
                                $('.paypal_button_div').addClass('d-none');
                            } else {
                                $('.paypal_button_div').removeClass('d-none');
                            }
                            updateApproachingDiscounts(data.approachingDiscounts);
                            validateBasket(data);
                            if ($('#checkout-main').length || ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length)) {
                                // location.reload();
                                if (window.location.pathname && !window.location.pathname.toLowerCase().includes('cart')) {
                                    changeQtyCheck = true;
                                }
                            }
                            if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                                var cart = {};
                                cart = data.trackCartDatalayer;
                                _etmc.trackCart([{cart}]); // eslint-disable-line
                            }
                            if (headerCartCount && data && data.numItems) {
                                $(headerCartCount).text(data.numItems);
                            }
                            if (numOfItems && data && data.resources && data.resources.numberOfItems) {
                                $(numOfItems).html(data.resources.numberOfItems);
                            }
                            // Add new bonus products if any
                            var cartItems = data.items;
                            var latestItemsInCart = [];
                            cartItems.forEach(function (item) {
                                latestItemsInCart.push(item.UUID);
                                if ($('.uuid-' + item.UUID).length < 1) {
                                    if (item.isBonusProductLineItem && item.isBonusProductLineItem === true) {
                                        $('.cart-products').append(`<div class="product-line-item product-info  uuid-${item.UUID}" data-product-line-item="${item.UUID}">
                                                                <div class="item-image">
                                                                <img class="product-image" src="${item.images.small[0].url}" alt="${item.images.small[0].alt}" title="${item.images.small[0].title}">
                                                                </div>
                                                                <div class="item-details">
                                                                <div>Bonus Product</div>
                                                                <div class="line-item-name bolded">
                                                                    <a href="${item.productUrl}"> ${item.productName} </a>
                                                                </div>
                                                                <div class="item-attributes small-text">
                                                                    <div class="line-item-attributes"> Quantity: ${item.quantity} </div>
                                                                    <div class="line-item-availability availability-${item.UUID}"></div>
                                                                </div>
                                                                <div class="line-item-promo item-${item.UUID}">
                                                                    <div></div>
                                                                </div>
                                                                </div>
                                                                <div class="item-pricing">
                                                                <div class="line-item-unit-price">
                                                                    <div class="price " itemprop="offers" itemscope="" itemtype="http://schema.org/Offer"></div>
                                                                </div>
                                                                <div class="line-item-total-price">
                                                                    <div class="price  item-total-${item.UUID}" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer"></div>
                                                                </div>
                                                                </div>
                                                            </div>`);
                                    }
                                }
                            });
                            // Remove bonus items if any that are not part of updated basket items
                            var itemsToDelete = [];
                            $('.product-line-item.product-info').each(function () {
                                if (latestItemsInCart.indexOf($(this).data('product-line-item')) < 0) {
                                    itemsToDelete.push($(this).data('product-line-item'));
                                }
                            });
                            itemsToDelete.forEach(function (itemToDeleteuuid) {
                                if (itemToDeleteuuid !== 'hybridbundle') {
                                    $('.uuid-' + itemToDeleteuuid).remove();
                                }
                            });
                            $thisElement.closest('.warranty-prod').find('.js-itemwarranty-checkbox').each(function () {
                                $(this).attr('checked', true);
                            });
                            if ($('.checkout__steps-wrap').length) {
                                location.reload();
                                stopLoader = false;
                            }
                        }
                        if (stopLoader) {
                            $.spinner().stop();
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            } else {
                // remove warranty from cart or reduce quantity
                var removeurl;
                if ($(this).closest('.minicart_wrap__container').length) {
                    removeurl = $('.js-removewarranty-minicart').val();
                } else {
                    removeurl = $('.js-removewarranty-cart').val();
                }
                if ($('.cart.cart-page').length) {
                    $('body').spinner().start();
                } else {
                    $('.minicart_wrap').spinner().start();
                }
                $.ajax({
                    url: removeurl,
                    method: 'POST',
                    data: {
                        wpid: warrantyObj.warrantyPID,
                        quantity: parentqty,
                        parentpid: warrantyObj.parentpid,
                        parentuuid: warrantyObj.parentuuid
                    },
                    success: function (data) {
                        if (!data.error) {
                            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                            updateCartTotals(data);
                            if (!data.paypalApplicable) {
                                $('.paypal_button_div').addClass('d-none');
                            } else {
                                $('.paypal_button_div').removeClass('d-none');
                            }
                            updateApproachingDiscounts(data.approachingDiscounts);
                            validateBasket(data);
                            if ($('#checkout-main').length || ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length)) {
                                // location.reload();
                                if (window.location.pathname && !window.location.pathname.toLowerCase().includes('cart')) {
                                    changeQtyCheck = true;
                                }
                            }
                            if (typeof _etmc !== 'undefined'){ // eslint-disable-line
                                var cart = {};
                                cart = data.trackCartDatalayer;
                                _etmc.trackCart([{cart}]); // eslint-disable-line
                            }
                            if (headerCartCount && data && data.numItems) {
                                $(headerCartCount).text(data.numItems);
                            }
                            if (numOfItems && data && data.resources && data.resources.numberOfItems) {
                                $(numOfItems).html(data.resources.numberOfItems);
                            }
                            // Add new bonus products if any
                            var cartItems = data.items;
                            var latestItemsInCart = [];
                            cartItems.forEach(function (item) {
                                latestItemsInCart.push(item.UUID);
                                if ($('.uuid-' + item.UUID).length < 1) {
                                    if (item.isBonusProductLineItem && item.isBonusProductLineItem === true) {
                                        $('.cart-products').append(`<div class="product-line-item product-info  uuid-${item.UUID}" data-product-line-item="${item.UUID}">
                                                                <div class="item-image">
                                                                <img class="product-image" src="${item.images.small[0].url}" alt="${item.images.small[0].alt}" title="${item.images.small[0].title}">
                                                                </div>
                                                                <div class="item-details">
                                                                <div>Bonus Product</div>
                                                                <div class="line-item-name bolded">
                                                                    <a href="${item.productUrl}"> ${item.productName} </a>
                                                                </div>
                                                                <div class="item-attributes small-text">
                                                                    <div class="line-item-attributes"> Quantity: ${item.quantity} </div>
                                                                    <div class="line-item-availability availability-${item.UUID}"></div>
                                                                </div>
                                                                <div class="line-item-promo item-${item.UUID}">
                                                                    <div></div>
                                                                </div>
                                                                </div>
                                                                <div class="item-pricing">
                                                                <div class="line-item-unit-price">
                                                                    <div class="price " itemprop="offers" itemscope="" itemtype="http://schema.org/Offer"></div>
                                                                </div>
                                                                <div class="line-item-total-price">
                                                                    <div class="price  item-total-${item.UUID}" itemprop="offers" itemscope="" itemtype="http://schema.org/Offer"></div>
                                                                </div>
                                                                </div>
                                                            </div>`);
                                    }
                                }
                            });
                            // Remove bonus items if any that are not part of updated basket items
                            var itemsToDelete = [];
                            $('.product-line-item.product-info').each(function () {
                                if (latestItemsInCart.indexOf($(this).data('product-line-item')) < 0) {
                                    itemsToDelete.push($(this).data('product-line-item'));
                                }
                            });
                            itemsToDelete.forEach(function (itemToDeleteuuid) {
                                if (itemToDeleteuuid !== 'hybridbundle') {
                                    $('.uuid-' + itemToDeleteuuid).remove();
                                }
                            });
                            $thisElement.closest('.warranty-prod').find('.js-itemwarranty-checkbox').each(function () {
                                $(this).attr('checked', false);
                            });
                            if ($('.checkout__steps-wrap').length) {
                                location.reload();
                                stopLoader = false;
                            }
                        }
                        if (stopLoader) {
                            $.spinner().stop();
                        }
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        }
    });
};
