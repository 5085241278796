/**
 * Component: CrossBrowserTransparentVideo
 */

export default class CrossBrowserTransparentVideo {
    constructor() {
        this.$videos = $('.js-transparent-video');
        this.init();
    }

    supportsHEVCAlpha() {
        const navigator = window.navigator;
        const ua = navigator.userAgent.toLowerCase();
        const hasMediaCapabilities = !!(navigator.mediaCapabilities && navigator.mediaCapabilities.decodingInfo);
        const isSafari = ((ua.indexOf('safari') !== -1) && (!(ua.indexOf('chrome') !== -1) && (ua.indexOf('version/') !== -1)));
        return isSafari && hasMediaCapabilities;
    }

    isIE11() {
        return !!window.navigator.userAgent.match(/Trident\/7\./, []);
    }

    init() {
        this.$videos.each((idx, video) => {
            const sourceName = $(video).data('source');
            const sourceTag = document.createElement('source');

            if (!this.isIE11()) {
                sourceTag.src = this.supportsHEVCAlpha() ? `${sourceName}.mov` : `${sourceName}.webm`;
            }

            $(video).append(sourceTag);
        });
    }
}
