'use strict';

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @param {string} selectorClasses - Pass throguh classes to update the selector
 * @return {undefined}
 */
function updateDom($results, selector, selectorClasses) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());

    if (selectorClasses != null) {
        $(selector).attr('class', selectorClasses);
    }
}

/**
 * Parse query string
 *
 * @param {string} query - permalink
 * @return {Object} - return object with page size
 */
function parseQueryString(query) {
    var vars = query.split('&');
    var queryString = {};
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);

        if (typeof queryString[key] === 'undefined') {
            queryString[key] = decodeURIComponent(value);
        } else if (typeof queryString[key] === 'string') {
            var arr = [queryString[key], decodeURIComponent(value)];
            queryString[key] = arr;
        } else {
            queryString[key].push(decodeURIComponent(value));
        }
    }
    return queryString;
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');

        $results
            .find('.' + $(this)[0].className.replace(/ /g, '.'))
            .addClass('active');
    });

    updateDom($results, '.refinements');
}

/**
 * Check classes on .grid-header
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleGridHeader($results) {
    updateDom($results, '.grid-header', $results.find('.grid-header').attr('class'));
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements,
        '.grid-header': handleGridHeader
    };

    // Update DOM elements that do not require special handling
    [
        '.cat-top-container',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar',
        '.nav-result-count',
        '.js-category-pills'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * This function retrieves another page of content to display in the content search grid
 * @param {JQuery} $element - the jquery element that has the click event attached
 * @param {JQuery} $target - the jquery element that will receive the response
 * @return {undefined}
 */
function getContent($element, $target) {
    var showMoreUrl = $element.data('url');
    $.spinner().start();
    $.ajax({
        url: showMoreUrl,
        method: 'GET',
        success: function (response) {
            $target.append(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * Update current and canonical URL
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateUrls(response) {
    var $permaLinkUrls = $('.permalink', response);

    if ($permaLinkUrls.length > 0) {
        $permaLinkUrls.each(function () {
            var $permaLinkUrl = $(this);
            var permalink = $permaLinkUrl.val();

            var parsedUrl = parseQueryString(permalink);

            if (!$.isEmptyObject(parsedUrl)) {
                $("link[rel^='canonical']", $permaLinkUrl.closest('head')).attr('href', permalink);
                history.pushState(null, null, permalink);
            }
        });
    }
}

/**
 * Toggle Filter Bar
 *
 */
function toggleFilterBar() {
    var $parent = $('.js-search-results');
    var $btn = $('.js-nav-cta');
    var $refinementBar = $parent.find('.js-refinement-filter');
    var $refinementFilterResult = $('.b-refinement-filter__result');

    $btn.toggleClass('b-nav-cta--active');
    $refinementBar.toggleClass('b-refinement-filter--active');

    if ($(window).width() < 975) {
        $('body').toggleClass('no-body-scroll');
    } else if ($btn.hasClass('b-nav-cta--scrollable') && $btn.hasClass('b-nav-cta--active') && $refinementFilterResult.length > 0) {
        var offsetTop = $refinementFilterResult.offset().top;
        var scrollTopPosition = offsetTop - 160;

        $('html, body').animate({
            scrollTop: scrollTopPosition
        }, 500);
    }
}


/**
 * Hide Filter Bar
 *
 */
function hideFilterBar() {
    var $parent = $('.js-search-results');
    var $btn = $('.js-nav-cta');
    var $refinementBar = $parent.find('.js-refinement-filter');

    $btn.removeClass('b-nav-cta--active');
    $refinementBar.removeClass('b-refinement-filter--active');
    $('body').removeClass('no-body-scroll');
}

module.exports = {
    filter: function () {
        // Display refinements bar when Filter button clicked
        $('.container').on('click', 'button.js-filter-results', toggleFilterBar);
        if ($(window).width() > 975) {
            toggleFilterBar();
        }
    },

    scroll: function () {
        var ScrollMagic = require('scrollmagic');

        if (ScrollMagic) {
            var headerHeight = $('header').height();
            var controller = new ScrollMagic.Controller();

            new ScrollMagic.Scene({
                triggerElement: '.js-refinement-filter',
                triggerHook: 0,
                offset: -headerHeight,
                reverse: true
            }).setClassToggle('.js-nav-cta', 'b-nav-cta--scrollable').addTo(controller);
        }
    },

    readMore: function () {
        var readMore = 'Read More';
        var readLess = 'Read Less';

        $(document).on('click', '.js-read-more-btn', function () {
            var isCollapsed = $(this).data('is-collapsed');
            var $wrapper = $('.js-read-more-wrapper');
            var $container = $('.js-read-more-container');
            $wrapper.toggleClass('category-hero__text--collapsed');
            $wrapper.height(!isCollapsed ? $container.outerHeight() + 5 : 40);
            $(this).html(isCollapsed ? readMore : readLess);
            $(this).data('is-collapsed', !isCollapsed);
        });

        $(document).on('click', '.js-read-more-arrow', function () {
            var container = $(this).closest('.js-read-more-container');
            container.toggleClass('hide-content');
        });
    },

    closeRefinments: function () {
        // Refinements close button
        $('.container').on('click', '.refinement-bar button.close', hideFilterBar);
    },

    resize: function () {
        // Close refinement bar and hide modal background if user resizes browser
        $(window).resize(hideFilterBar);
    },

    sort: function () {
        // Handle sort order menu selection
        $('.container').on('change click', '[name=sort-order], .js-sort-order-option', function (e) {
            e.preventDefault();

            var url = this.value;
            var $preservedPageSizeEl = $('.js-saved-page-size');
            var preservedPageSize = $preservedPageSizeEl.length && $preservedPageSizeEl.text().trim();
            $preservedPageSizeEl.remove();
            var originalPageSize;

            if (preservedPageSize) {
                var szParam = url.match(/sz=(\d+)/) ? url.match(/sz=(\d+)/)[0] : '';
                originalPageSize = szParam && szParam.match(/\d+/) ? szParam.match(/\d+/)[0] : null;
                url = url.replace(/(sz=)\d+/, `$1${preservedPageSize}`);
            }

            $.spinner().start();
            $(this).trigger('search:sort', this.value);
            var $prevSelected = $('.js-sort-order-option[selected="selected"]');
            $prevSelected.removeAttr('selected');
            $prevSelected.children('.checkmark').addClass('d-none');
            $(this).attr('selected', 'selected');
            $(this).children('.checkmark').removeClass('d-none');
            $.ajax({
                url: url,
                data: {
                    selectedUrl: this.value,
                    originalSz: originalPageSize
                },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showMore: function () {
        // Show more products
        $('.container').on('click', '.show-more button', function (e) {
            e.stopPropagation();
            var showMoreUrl = $(this).data('url');

            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    updateUrls(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    applyFilter: function () {
        // Handle refinement value selection and reset click
        $('.container').on(
            'click',
            '.refinements li button:not(".js-sort-order-option"), .refinement-bar a.reset, .filter-value a, .swatch-filter a, .js-category-pill',
            function (e) {
                e.preventDefault();
                e.stopPropagation();

                $.spinner().start();
                $(this).trigger('search:filter', e);
                var url = $(this).data('href');
                $.ajax({
                    url: url,
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: e.currentTarget.href
                    },
                    method: 'GET',
                    success: function (response) {
                        parseResults(response);
                        var headerHeight = $('header').height();
                        var contententBlock = $('.js-refinement-filter');
                        $(window).scrollTop(contententBlock.offset().top - headerHeight);
                        $.spinner().stop();
                        $('body .custom-select-dropdown').select2({
                            minimumResultsForSearch: -1,
                            dropdownPosition: 'below',
                            width: 'resolve'
                        });
                        if ($('.refinements').children().length < 1) {
                            $('.reset').click();
                        }
                        window.history.pushState({}, '', url);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        );
    },

    showContentTab: function () {
        // Display content results from the search
        $('body').on('click', '.content-search', function () {
            $(this).addClass('active');
            $('#content-search-results-pane').addClass('active');
            $('.product-search').removeClass('active');
            $('#product-search-results').removeClass('active');
            if ($('#content-search-results').html() === '') {
                getContent($(this), $('#content-search-results'));
            }
            $('#content-search-results').show();
        });

        // Display the next page of content results from the search
        $('.container').on('click', '.show-more-content button', function () {
            getContent($(this), $('#content-search-results .result-count'));
            $('.show-more-content').remove();
        });
    },

    showProductTab: function () {
        // Display content results from the search
        $('.container').on('click', '.product-search', function () {
            $(this).addClass('active');
            $('.content-search').removeClass('active');
            $('#content-search-results-pane').removeClass('active');
            $('#content-search-results').hide();
            $('.product-search').addClass('active');
            $('#product-search-results').addClass('active');
        });
    },

    resetFilters: function () {
        // Display content results from the search
        $('.page[data-action=Search-Show]').on('click', '.refinement-bar .reset.btn', function () {
            $.spinner().start();
            window.location.href = $(this).data('href');
        });
    },

    colorAttribute: function () {
        $(document).on('click', '.color-swatches a:not(.swatch-ellipsis)', function (e) {
            e.preventDefault();

            var swatchImg = $(e.currentTarget).data('swatchimg');
            var swatchUrl = $(e.currentTarget).attr('href');
            var $productContainer = $(this).closest('.set-item'); // Need to check and see what classes a product set tile has if any?

            if (!$productContainer.length) {
                $productContainer = $(this).closest('.grid-tile');
            }

            var currentImg = $($productContainer).find('.tile-image').attr('src');

            if ($(this).attr('disabled') || currentImg === swatchImg) {
                return;
            }

            $($productContainer).find('.tile-image').attr('src', swatchImg);
            $($productContainer).find('.pdp-link a').attr('href', swatchUrl);
            $($productContainer).find('.image-container > a:not(.quickview)').attr('href', swatchUrl);
            $($productContainer).find('.swatch-ellipsis').attr('href', swatchUrl);
        });
    },

    pressShowMore: function () {
        // Display the next page of content results from the search
        $('.container').on('click', 'button#press-load-more', function (e) {
            e.preventDefault();
            getContent($(this), $('.press-list__articles'));
            $('.show-more-content').remove();
        });
    },

    contentResultsShowMore: function () {
        // Display the next page of content results from the search
        $('.container').on('click', 'button.content-page-load-more', function () {
            getContent($(this), $('#content-search-results .search-page-results'));
            $(this).remove();
        });
    },
    updateDom: updateDom
};
