'use strict';

var cart = require('../cart/cart');

module.exports = function () {
    cart();

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.item-cart .cart-count').text(count.quantityTotal);
            if (count.quantityTotal > 0) {
                $('.header__utilities-item.item-cart.minicart').addClass('indicator');
            } else {
                $('.header__utilities-item.item-cart.minicart').removeClass('indicator');
            }
        }
    });

    try {
        $('.mob-byb-discount').css('width', $('.hybridbundle-line-item').width());
        $(window).on('resize', function () {
            var win = $(this);
            if (win.width() < 600 && $('.mob-byb-discount').length) {
                $('.mob-byb-discount').css('width', $('.hybridbundle-line-item').width());
            }
        });
    } catch (error) {
        console.log(error);
    }

    $('.minicart').on('mouseenter focusin touchstart', function () {
        if ($('.cart-page').length !== 0) {
            return;
        }
        if ($('.search:visible').length === 0) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            $('.minicart .popover').addClass('show');
            // $('.minicart .popover').spinner().start();
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').parent().each(function () {
                    if (!$(this).hasClass('mobile-header')) {
                        $(this).children('.minicart .popover').append(data);
                    }
                });
                // $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').empty();
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
    function createErrorNotification(message) {
        if ($('.alert-danger').length < 1) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
             'fade show" role="alert">' +
             '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
             '<span aria-hidden="true">&times;</span>' +
             '</button>' + message + '</div>';

            $('.cart-error').append(errorHtml);
        } else if ($('body').find('#checkout-main')) {
            var errorHtml2 = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
             'fade show" role="alert">' +
             '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
             '<span aria-hidden="true">&times;</span>' +
             '</button>' + message + '</div>';

            $('.cart-error').empty().append(errorHtml2);
        }
    }

/**
 * Event Handlers for Quantity Controls
 */
    $('body').on('click', '.liq-minus', function () {
        var quantity = $(this).siblings('.quantity').val();
        if (quantity !== '1') {
            /* $('.liq-minus').removeAttr('disabled'); */
            this.parentNode.querySelector('input[type=number]').stepDown();
            var updatedQuantity = $(this).siblings('.quantity').val();
            if (updatedQuantity === '1') {
                $(this).attr('disabled', true).text('');
            }
            $(this).parent().find('input[type=number]').addClass('reduceqty');
            $(this).parent().find('input[type=number]').trigger('change');
        }
    });
    $('body').on('click', '.liq-plus', function () {
        var ATS = '10';
        if ($(this).siblings('.quantity').data('inventory') < 10) {
            ATS = $(this).siblings('.quantity').data('inventory').toString();
        }
        var quantity = $(this).siblings('.quantity').val();
        if (ATS === quantity) {
            var errmsg = 'You have reached the max quantity';
            createErrorNotification(errmsg);
            $('.js-minicart-block').animate({
                scrollTop: 0
            });
        } else {
            this.parentNode.querySelector('input[type=number]').stepUp();
            $(this).siblings('.liq-minus').removeAttr('disabled').text('-');
            $(this).parent().find('input[type=number]').trigger('change');
        }
    });

/**
 * MODAL/POPUP FUNCTIONALITY
 */
    var modalOverlay = document.querySelector('.modal-overlay');
    var cartIcon = document.querySelector('.item-cart');
    var miniCart = document.querySelector('.minicart_wrap');
    var body = document.querySelector('body');
    var header = document.querySelector('.header');
    var headerBanner = document.querySelector('.header__banner');

/**
 * Removes classes to remove modal overlays.
 */
    function closeOverlays() {
        $('#removeProductModal').find('button.close').click();
        body.classList.remove('no-scroll');
        body.classList.remove('mini-cart-active');
        if (modalOverlay) {
            modalOverlay.classList.remove('active');
        }
        if (miniCart) {
            miniCart.classList.remove('active');
        }
    }

/**
 * Opens minicart.
 * @param {Object} e jquery event object
 */
    function openCart(e) {
        if ($('.page').attr('data-action') !== 'Cart-Show') {
            if ($('body .page[data-action=Cart-Show]').length < 1) {
                if ($('.minicart_wrap').children().length) {
                    $('body').addClass('mini-cart-active');
                    $('body').addClass('no-scroll');
                    $('.modal-overlay').addClass('active');
                    $('.minicart_wrap').addClass('active');
                } else {
                    if (!$('.minicart_wrap').hasClass('express-checkout')) { // eslint-disable-line no-lonely-if
                        var url = $('.cart-link').attr('data-href');
                        $.get(url, function (data) {
                            if (!data.error) {
                                $('.minicart_wrap').empty();
                                $('.minicart_wrap').parent().each(function () {
                                    if (!$(this).hasClass('mobile-header')) {
                                        $(this).children('.minicart_wrap').append(data);
                                    }
                                });
                                if (typeof affirm !== 'undefined') { // eslint-disable-line
                                    affirm.ui.ready(function () { // eslint-disable-line
                                        affirm.ui.refresh(); // eslint-disable-line
                                    });
                                }
                                modalOverlay.classList.add('active');
                                miniCart.classList.add('active');
                                body.classList.add('mini-cart-active');
                                body.classList.add('no-scroll');
                                header.classList.add('cart-open');
                                if (headerBanner) {
                                    headerBanner.classList.add('cart-open');
                                }
                                $('.mob-byb-discount').css('width', $('.hybridbundle-line-item').width());
                            } else if (data.error && data.message === 'Express Checkout') {
                                $('.minicart_wrap').empty();
                                body.classList.remove('no-scroll');
                                miniCart.classList.add('express-checkout');
                                if (e.type === 'touchstart') {
                                    window.location.href = $(e.target).closest('a').prop('href');
                                }
                            }
                        });
                        // $('.minicart_wrap').spinner().start();
                    }
                }
            }
        }
    }

/**
 * Cart Icon click
 */
    if (cartIcon) {
        if ($('body').width() < 1200) {
            cartIcon.addEventListener('touchstart', (e) => {
                e.preventDefault();
                openCart(e);
            });
        } else {
            cartIcon.addEventListener('mouseover', (e) => {
                e.preventDefault();
                openCart(e);
            });
        }
    }

/**
 * Mini Cart Close Click
 */
    $('body').on('touchstart click', '.minicart_wrap__close, a.close-cart', function (e) {
        if (e.cancelable) {
            e.preventDefault();
        }
        closeOverlays();
    });
    $('body').on('mouseover', '.minicart_wrap__close, a.close-cart', function (e) {
        if (e.cancelable) {
            e.preventDefault();
        }
        closeOverlays();
    });
    $('body').on('click', '.minicart_wrap__close, a.close-cart', function (e) {
        if (e.cancelable) {
            e.preventDefault();
        }
        closeOverlays();
    });


    $('.page').children().not('.header').not('.minicart_wrap')
    .on('mouseenter mouseover', function () {
        closeOverlays();
    });

    $(document).on('click', 'body,html', function (e) {
        // eslint-disable-next-line no-console
        if ($('minicart_wrap').hasClass('active')) {
            var sideIconToggle = document.querySelector('.minicart_wrap');
            var itemCart = document.querySelector('.item-cart');
            var flyoutCart = document.querySelector('#flyout-cart');
            if (!sideIconToggle.contains(e.target) && !itemCart.contains(e.target) && (!flyoutCart || !flyoutCart.contains(e.target))) {
                e.preventDefault();
                closeOverlays();
            }
        }
    });

/**
* Mini Cart Close On Browser Tab Change
*/
    document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'hidden') {
            if ($('.minicart_wrap').hasClass('active')) {
                closeOverlays();
            }
        }
    });

/**
 * Modal Overlay Click
 */
    if (modalOverlay) {
        modalOverlay.addEventListener('click', () => {
            closeOverlays();
        });
    }
};
