'use strict';
var slickConfigs = require('../config/slickConfigs');

/**
 * Init globally reusable carousels
 */

module.exports = {
    heroCarousels: function () {
        $('.hero-caro').not('.slick-initialized').slick(slickConfigs.hero);
    },
    productTileCarousels: function () {
        $('.product-tile-caro').not('.slick-initialized').slick(slickConfigs.productTiles);
    },
    pdpCarousels: function () {
        $('div.page-wrapper:not(.npi-pro):not(.npi-security) .product-overview__gallery-slides').not('.slick-initialized').slick(slickConfigs.pdp);
    }
};
